class ValidateService {

  checkRequired(value) {
    return value && value.trim();
  }

  checkEquals(valueA, valueB) {
    return valueA === valueB;
  }

  valueRequired(value, text = '') {
    
  }
}

export default Object.freeze(new ValidateService());
