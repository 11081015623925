import { combineReducers, createStore } from 'redux';
import SettingStore from './SettingStore';
import MessageStore from './MessageStore';

const reducers = combineReducers({
  setting: SettingStore.reducer,
  message: MessageStore.reducer,
});

export default createStore(reducers);
