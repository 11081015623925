import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { CreditCardOutlined, WechatOutlined, AlipayCircleOutlined } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import * as qrcode from 'qrcode';

import Auth from '../Auth/Auth';
// import JgooPayment from './JgooPayment';
import GlobepayPayment from './GlobepayPayment';
import TitleElement from '../../components/TitleElement';
import LoaderElement from '../../components/LoaderElement';
import HttpService from '../../services/HttpService';
import StorageService from '../../services/StorageService';
import { mainColor, hightLightColor } from '../../metadata';

import { connect } from 'react-redux';


class Payment extends React.Component {

  state = {
    plan: this.props.location.state.plan || 'standard',
    quantity: 1,
    payway: '',
    isLogin: false,
    qrcode: '',
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = () => {
    const isLogin = StorageService.getItem('connectyCubeToken') && StorageService.getItem('token');
    this.setState({isLogin});
  };

  setPayway = async (e, payway) => {
    this.setState({payway});
    if ('creditCard' === payway) {
      const session = await HttpService.createStripePaymentSession(this.state);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLICK_KEY);
      await stripe.redirectToCheckout({ sessionId: session.id });
    } else {
      await this.setGlobepayWay(payway);
      // await this.setJgooPayway(payway);
    }
  };

  setPlan = async event => {
    this.setState({
      plan: event.target.value,
      payway: '',
    });
    // await this.setJgooPayway(this.state.payway);
  };

  setQuantity = async event => {
    this.setState({
      quantity: Math.max(1, parseInt(event.target.value)),
      payway: '',
    });
    // await this.setJgooPayway(this.state.payway);
  };

  setGlobepayWay = async payway => {
    if ('Wechat' === payway || 'Alipay' === payway) {
      this.setState({
        qrcode: '',
        paymentId: '',
      });
      const session = await HttpService.createGlobepayPaymentSession({
        ...this.state,
        payway,
      });

      if (session.success) {
        this.setState({
          qrcode: session.qrcode_img,
          paymentId: session.paymentId,
        });
      }
    }
  }

  setJgooPayway = async payway => {
    if ('Wechat' === payway || 'Alipay' === payway) {
      this.setState({
        qrcode: '',
        paymentId: '',
      });
      const session = await HttpService.createWechatPaymentSession({
        ...this.state,
        payway,
      });
      if (session.success) {
        const options = {
          errorCorrectionLevel: 'H',
          type: 'image/jpeg',
          quality: 0.7,
          margin: 1,
          color: {
            dark:"#222222",
            light:"#f0f0f0"
          }
        }
        qrcode.toDataURL(session.url, options, (error, qrcode) => {
          this.setState({
            qrcode,
            paymentId: session.paymentId,
          });
        });
      }
    }
  };

  render() {
    return (
      <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', height: '800px'}} className="flex-column">
          {this.state.isLogin ? (Object.keys(this.props.setting.pricingPlans).length > 0 && (
            <React.Fragment>
              <TitleElement
                title="Select Quantity"
                titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
                // description="Welcome!"
                // descriptionStyle={{margin: '24px 0px 36px', textAlign: 'center'}}
              />
              <TextField
                select
                label="Plan"
                color="secondary"
                style={{width: '300px', lineHeight: '35px', marginBottom: '24px', margin: '0 auto 24px'}}
                value={this.state.plan}
                // onChange={event => this.setState({plan: event.target.value})}
                onChange={this.setPlan}
              >
                {Object.keys(this.props.setting.pricingPlans).map(plan => (
                  <MenuItem key={plan} value={plan}>
                    {this.props.setting.pricingPlans[plan].title} - {this.props.setting.pricingPlans[plan].price}￥
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Quantity"
                color="secondary"
                style={{width: '300px', marginBottom: '24px', margin: '0 auto 24px'}}
                value={this.state.quantity}
                // onChange={event => this.setState({ quantity: Math.max(1, parseInt(event.target.value)) })}
                onChange={this.setQuantity}
                type="number"
              />
              <TextField
                label="Total Price"
                color="secondary"
                style={{width: '300px', marginBottom: '24px', margin: '0 auto 24px'}}
                value={this.props.setting.pricingPlans[this.state.plan].price * this.state.quantity}
                disabled
                // type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="start">￥</InputAdornment>,
                }}
              />
              <TitleElement
                title="Select Payment Methods"
                titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
              />
              <ToggleButtonGroup
                value={this.state.payway}
                exclusive
                onChange={this.setPayway}
                aria-label="text alignment"
                style={{marginBottom: '24px', margin: '0 auto'}}
              >
                <ToggleButton value="creditCard" aria-label="right aligned">
                  <CreditCardOutlined style={{fontSize: '26px', color: mainColor}} />
                </ToggleButton>
                <ToggleButton value="Wechat" aria-label="left aligned">
                  <WechatOutlined style={{fontSize: '28px', color: '#00c161'}} />
                </ToggleButton>
                <ToggleButton value="Alipay" aria-label="left aligned">
                  <AlipayCircleOutlined style={{fontSize: '28px', color: '#2476fe'}} />
                </ToggleButton>
              </ToggleButtonGroup>
              {'creditCard' === this.state.payway && (
                <LoaderElement
                  loading={true}
                  loadingText="Processing credit card payment"
                  style={{marginTop: '36px'}}
                />
              )}
              {('Wechat' === this.state.payway || 'Alipay' === this.state.payway) && (
                <LoaderElement
                  loading={!this.state.qrcode}
                  loadingText={`Processing ${'Wechat' === this.state.payway ? 'weChat' : 'Alipay'} payment...`}
                  style={{marginTop: '36px'}}
                >
                  <GlobepayPayment
                    history={this.props.history}
                    qrcode={this.state.qrcode}
                    paymentId={this.state.paymentId}
                  />
                </LoaderElement>
              )}
            </React.Fragment>
          )) : (
            <div style={{margin: '0 auto'}}>
              <TitleElement
                title="Login English Detective"
                titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
                description="Quick Login/Register to Pay"
                descriptionStyle={{margin: '24px 0px 36px', textAlign: 'center'}}
              />
              <Auth initialize={this.initialize} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    setting: state.setting,
  })
)(Payment);
