import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import MessageElement from '../../components/MessageElement';
import ValidateService from '../../services/ValidateService';
import { mainColor } from '../../metadata';

export default class PasswordLogin extends React.Component {

  state = {
    type: 'login', // or 'register'
    login: '',
    password: '',
    rePassword: '',
    snackbarOpen: false,
    snackbarSeverity: 'error',
    snackbarMessage: '',
    loginLoading: false,
    registerType: 'password',
  };

  checkRequired = () => {
    if (!ValidateService.checkRequired(this.state.login)) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Please input username!',
      });
      return false;
    }
    if (!ValidateService.checkRequired(this.state.password)) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Please input password!',
      });
      return false;
    }
    return true;
  };

  login = async event => {
    if (!this.checkRequired()) {
      return false;
    }
    this.setState({ loginLoading: true });
    try {
      await this.props.login(this.state);
    } catch (error) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Login failed, username or password is not right!',
      });
    }
    this.setState({ loginLoading: false });
  };

  register = async event => {
    if (!this.checkRequired()) {
      return false;
    }
    if (!ValidateService.checkEquals(this.state.password, this.state.rePassword)) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Passwords are not the same!',
      });
      return false;
    }
    this.setState({ loginLoading: true });
    try {
      await this.props.register(this.state);
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'success',
        snackbarMessage: 'Register success!',
        password: '',
        type: 'login',
      });
    } catch (error) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Register failed, username exists!',
      });
    }
    this.setState({ loginLoading: false });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{padding: '12px 0px 24px'}}>
          <div style={{marginBottom: '12px', textAlign: 'center'}}>
            <TextField label="Username" color="secondary" style={{width: '300px'}}
              value={this.state.login}
              onChange={event => this.setState({login: event.target.value})}
            />
          </div>
          <div style={{marginBottom: '12px', textAlign: 'center'}}>
            <TextField label="Password" color="secondary" style={{width: '300px'}} type="password"
              value={this.state.password}
              onChange={event => this.setState({password: event.target.value})}
            />
          </div>
          {'login' === this.state.type && (
            <React.Fragment>
              <div style={{marginTop: '36px', textAlign: 'center'}}>
                <Button variant="contained" color="secondary"
                  style={{width: '300px', textTransform: 'none', padding: '5px 12px', backgroundColor: mainColor, fontSize: '16'}}
                  // onClick={event => this.props.login(this.state)}
                  onClick={this.login}
                  disabled={this.state.loginLoading}
                >
                  {this.state.loginLoading && <CircularProgress size={16} style={{color: '#f0f0f0'}} />}
                  <span style={{marginLeft: '6px', color: '#f0f0f0'}}>Login{this.state.loginLoading && ' ...'}</span>
                </Button>
              </div>
              <div style={{width: '300px', margin: '12px auto', textAlign: 'right'}}>
                <Button variant="text" color="secondary"
                  style={{width: '150px', textTransform: 'none', color: mainColor, padding: '5px 12px', justifyContent: 'center'}}
                  onClick={event => this.setState({type: 'register'})}
                  disabled={this.state.loginLoading}
                >
                  <ArrowRightOutlined style={{marginRight: '6px'}} />
                  Create Account
                </Button>
              </div>
            </React.Fragment>
          )}
          {'register' === this.state.type && (
            <React.Fragment>
              <div style={{marginBottom: '12px', textAlign: 'center'}}>
                <TextField label="rePassword" color="secondary" style={{width: '300px'}} type="password"
                  value={this.state.rePassword}
                  onChange={event => this.setState({rePassword: event.target.value})}
                />
              </div>
              <div style={{marginTop: '36px', textAlign: 'center'}}>
                <Button variant="contained" color="secondary"
                  style={{width: '300px', textTransform: 'none', padding: '5px 12px', backgroundColor: mainColor}}
                  onClick={this.register}
                  disabled={this.state.loginLoading}
                >
                  {this.state.loginLoading && <CircularProgress size={16} style={{color: '#f0f0f0'}} />}
                  <span style={{marginLeft: '6px', color: '#f0f0f0'}}>Register{this.state.loginLoading && ' ...'}</span>
                </Button>
              </div>
              <div style={{width: '300px', margin: '12px auto', textAlign: 'left'}}>
                <Button variant="text" color="secondary"
                  style={{width: '105px', textTransform: 'none', color: mainColor, padding: '5px 12px', justifyContent: 'center'}}
                  onClick={event => this.setState({type: 'login'})}
                  disabled={this.state.loginLoading}
                >
                  <ArrowLeftOutlined style={{marginRight: '6px'}} />
                  To Login
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
        <MessageElement
          open={this.state.snackbarOpen}
          onClose={() => this.setState({snackbarOpen: false})}
          severity={this.state.snackbarSeverity}
          message={this.state.snackbarMessage}
        />
      </React.Fragment>
    );
  }
}
