import React, { Component } from 'react';
import { hightLightColor } from '../../metadata';

export default class PoetryCorner extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="anglo-container">
          <h3
            style={{
              textAlign: 'center',
              color: hightLightColor,
              fontSize: '28px'
            }}
          >
            Poetry Corner
          </h3>
          <p>
            If you wish to submit your own poem for entry into poetry corner,
            please{' '}
            <a href="http://www.englishtalkabout.com/chat" target="_blank" rel="noreferrer">
              chat with the English Detective
            </a>
          </p>
          <h4 style={{ fontSize: '16px', margin: '0px 0px 12px' }}>
            After the Winter
          </h4>
          <p>Some day, when trees have shed their leaves</p>
          <p>And against the morning’s white</p>
          <p>The shivering birds beneath the eaves</p>
          <p>Have sheltered for the night,</p>
          <p>We’ll turn our faces southward, love,</p>
          <p>Toward the summer isle</p>
          <p>Where bamboos spire the shafted grove</p>
          <p>And wide-mouthed orchids smile.</p>
          <br/>
          <p>And we will seek the quiet hill</p>
          <p>Where towers the cotton tree,</p>
          <p>And leaps the laughing crystal rill,</p>
          <p>And works the droning bee.</p>
          <p>And we will build a cottage there</p>
          <p>Beside an open glade,</p>
          <p>With black-ribbed blue-bells blowing near,</p>
          <p>And ferns that never fade.</p>
          <h4 style={{ fontSize: '16px', margin: '0px 0px 12px' }}>
            Claude Mckay
          </h4>
        </div>
      </React.Fragment>
    );
  }
}
