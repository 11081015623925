import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { KeyOutlined, WechatOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';

import PasswordLogin from './PasswordLogin';
import LoaderElement from '../../components/LoaderElement';
import HttpService from '../../services/HttpService';
import WechatService from '../../services/WechatService';
import StorageService from '../../services/StorageService';
import ConnectyCubeService from '../../services/ConnectyCubeService';
import { mainColor } from '../../metadata';


export default class Auth extends React.Component {

  wechatIntervalIndex = null;

  state = {
    loginType: 'password',
  };

  componentWillUnmount() {
    clearInterval(this.wechatIntervalIndex);
  }

  register = async userCredentials => {
    try {
      const connectyCubeRegisterResult = await ConnectyCubeService.signup(userCredentials);
      console.log(connectyCubeRegisterResult);
      userCredentials.userId = connectyCubeRegisterResult.user.id;
      await HttpService.register(userCredentials);
    } catch (e) {
      StorageService.clear();
      throw e;
    }
  };

  login = async userCredentials => {
    try {
      const connectCubeLoginResult = await ConnectyCubeService.createSession(userCredentials);

      StorageService.setItem('connectyCubeUserId', connectCubeLoginResult.user.id);
      StorageService.setItem('connectyCubeToken', connectCubeLoginResult.token);
      StorageService.setItem('connectyCubeUsername', userCredentials.login);
      StorageService.setItem('connectyCubePassword', userCredentials.password);
      StorageService.setItem('displayName', connectCubeLoginResult.user.full_name);
      StorageService.setItem('avatar', connectCubeLoginResult.user.avatar);
      StorageService.setItem('connectyCubeEmail', connectCubeLoginResult.user.email);      

      userCredentials.connectyCubeUserId = connectCubeLoginResult.user.id;
      const angloetaLoginResult = await HttpService.login(userCredentials);
      StorageService.setItem('userId', angloetaLoginResult.userId);
      StorageService.setItem('token', angloetaLoginResult.token);
      StorageService.setItem('userType', angloetaLoginResult.userType);
      StorageService.setItem('connectyCubeAdminId', angloetaLoginResult.connectyCubeAdminId);

      this.props.initialize();
    } catch (e) {
      StorageService.clear();
      throw e;
    }
  };

  setLoginType = async (e, loginType) => {
    this.setState({loginType});
    switch (loginType) {
      case 'wechat':
        const wechatLoginUrl = WechatService.getWechatQrCodeApi(uuid());
        window.open(wechatLoginUrl);
        this.wechatIntervalIndex = setInterval(async () => {
          const userCredentials = {
            login: StorageService.getItem('wechatConnectyCubeUsername'),
            password: StorageService.getItem('wechatConnectyCubePassword'),
            full_name: StorageService.getItem('wechatDisplayName'),
            avatar: StorageService.getItem('wechatAvatar'),
            exists: StorageService.getItem('wechatUserExists'),
          };
          if (userCredentials.login && userCredentials.password) {
            clearInterval(this.wechatIntervalIndex);
            StorageService.clear();
            if (!userCredentials.exists) {
              userCredentials.registerType = 'wechat';
              await this.register(userCredentials);
            }
            await this.login(userCredentials);
          }
        }, 2000);
        break;
      default:
    }
  };

  render() {
    return (
      <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', margin: '0 auto'}}>
        <div style={{marginBottom: '48px', textAlign: 'center'}}>
          <ToggleButtonGroup
            value={this.state.loginType}
            exclusive
            onChange={this.setLoginType}
            aria-label="text alignment"
            style={{marginBottom: '24px'}}
          >
            <ToggleButton value="password" aria-label="right aligned">
              <KeyOutlined style={{fontSize: '26px', color: mainColor}} />
            </ToggleButton>
            <ToggleButton value="wechat" aria-label="left aligned">
              <WechatOutlined style={{fontSize: '28px', color: 'green'}} />
            </ToggleButton>
          </ToggleButtonGroup>
          {'password' === this.state.loginType && (
            <PasswordLogin login={this.login} register={this.register} />
          )}
          {'wechat' === this.state.loginType && (
            <LoaderElement loading={true} loadingText="WeChat login..." />
          )}
        </div>
      </div>
    );
  }
}
