import React from 'react';

import { hightLightColor } from '../../metadata';
import HttpService from '../../services/HttpService';
import TitleElement from '../../components/TitleElement';
import LoaderElement from '../../components/LoaderElement';

export default class Topic extends React.Component {

  state = {
    data: {},
    dataLoading: true
  };

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    this.setState({dataLoading: true})
    const topicId = new URLSearchParams(this.props.location.search).get("topicId")
    try {
      const topic = await HttpService.fetchTopic(topicId)
      this.setState({data: topic.data})
    } finally {
      this.setState({dataLoading: false})
    }
  }

  render() {
    return (
      <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', minHeight: '800px'}} className="flex-column">
          <LoaderElement loading={this.state.dataLoading}>
            <TitleElement
              title={this.state.data.title}
              titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
              descriptionStyle={{margin: '0px 0px 36px', textAlign: 'center'}}
            />
            <div style={{ padding: '12px 36px'}}
              dangerouslySetInnerHTML={{__html: this.state.data.content}}
            ></div>
          </LoaderElement>
        </div>
      </div>
    )
  }
}