class StorageService {

  storageEngine = window.localStorage;

  setItem(key, value) {
    this.storageEngine.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    const value = this.storageEngine.getItem(key);
    return JSON.parse(value);
  }

  removeItem(key) {
    this.storageEngine.removeItem(key);
  }

  clear() {
    this.storageEngine.clear();
  }

  isAdmin() {
    return this.getItem('connectyCubeUserId') && this.getItem('connectyCubeAdminId') === this.getItem('connectyCubeUserId');
  }
}

export default Object.freeze(new StorageService());
