import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

import LoaderElement from '../../components/LoaderElement';
import HttpService from '../../services/HttpService';
import StorageService from '../../services/StorageService';
import { mainColor, whiteTextColor } from '../../metadata';

export default class Billing extends React.Component {

  state = {
    loading: true,
    data: [],
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const responseData = await HttpService.fetchBilling({
      userId: StorageService.getItem('userId')
    });
    this.setState({
      loading: false,
      data: responseData.data,
    });
  }

  render() {
    return (
      <LoaderElement loading={this.state.loading}>
        <TableContainer>
          <Table>
            <TableHead style={{backgroundColor: '#fcfcfc'}}>
              <TableRow>
                <TableCell>Plans</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Quantities</TableCell>
                <TableCell align="left">Total Price</TableCell>
                <TableCell align="left">Start Time</TableCell>
                <TableCell align="left">End Time</TableCell>
                <TableCell align="left">Queries</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" style={{textTransform: 'capitalize'}}>
                    {row.plan}
                  </TableCell>
                  <TableCell align="left">
                    <span>{row.price / 100}</span>
                    <span style={{fontSize: '12px', color: '#666666', marginLeft: '3px'}}>￥</span>
                  </TableCell>
                  <TableCell align="left">
                    <span>{row.quantity}</span>
                    <span style={{fontSize: '12px', color: '#666666', marginLeft: '3px'}}>Months</span>
                  </TableCell>
                  <TableCell align="left">
                    <span>{row.quantity * row.price / 100}</span>
                    <span style={{fontSize: '12px', color: '#666666', marginLeft: '3px'}}>￥</span>
                  </TableCell>
                  <TableCell align="left">{moment(row.startAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell align="left">{moment(row.overdueAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell align="left">{row.usageQuery}/{row.countQuery}</TableCell>
                  <TableCell align="left">
                    {moment() > moment(row.overdueAt) && (
                      <Chip label="Expired" style={{ backgroundColor: '#f0f0f0', color: '#333333'}} />
                    )}
                    {moment() < moment(row.startAt) && (
                      <Chip label="Not Active" style={{ backgroundColor: mainColor, color: whiteTextColor}} />
                    )}
                    {(moment() < moment(row.overdueAt) && moment() > moment(row.startAt)) && (
                      row.usageQuery < row.countQuery ? (
                        <Chip label="Active" style={{ backgroundColor: 'green', color: whiteTextColor}} />
                      ) : (
                        <Chip label="Use up" style={{ backgroundColor: 'green', color: whiteTextColor}} />
                      )
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LoaderElement>
    );
  }
}
