import React from 'react';
import Grid from '@material-ui/core/Grid';
import { hightLightColor } from '../../metadata';

export default class Eula extends React.Component {

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }

  render() {
    return <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px'}} className="flex-column">

          <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>End-User License Agreement (EULA) of English Detective</h3>
          <div style={{backgroundColor: '#f0f0f0', padding: '12px 24px 24px', borderRadius: '6px', marginBottom: '48px'}}>
            <Grid container spacing={0}>
              <Grid item xs={24} lg={24}>




          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            This End-User License Agreement ("EULA") is a legal agreement
            between you and Anglo Overseas Commerce Ltd which owns the
            proprietary brand and software for English Detective.
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            This EULA agreement governs your acquisition and use of our English
            Detective software ("Software") directly from English Detective or
            indirectly through an English Detective authorized reseller or
            distributor (a "Reseller").
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            Please read this EULA agreement carefully before completing the
            installation process and using the English Detective software.It
            provides a license to use the English Detective software and
            contains warranty information and liability disclaimers.
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            If you register for a free trial of the English Detective software,
            this EULA agreement will also govern that trial. By clicking
            "accept" or installing and/or using the English Detective software,
            you are confirming your acceptance of the Software and agreeing to
            become bound by the terms of this EULA agreement.
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            If you are entering into this EULA agreement on behalf of a company
            or other legal entity, you represent that you have the authorityto
            bind such entity and its affiliates to these terms and conditions.
            If you do not have such authority or if you do not agree with the
            terms and conditions of this EULA agreement, do not install or use
            the Software, and you must not accept this EULA agreement.
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            This EULA agreement shall apply only to the Software supplied by
            English Detective herewith regardless of whether other software is
            referred to or described herein. The terms also apply to any English
            Detective updates, supplements, Internet-based services, and support
            services for the Software, unless other terms accompany those items
            on delivery. If so, those terms apply.
          </p>

          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            <p style={{fontWeight: 'bold', marginTop: '24px'}}>License Grant</p>
          </p>

          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            English Detective hereby grants you a personal, non-transferable,
            non-exclusive licence to use the English Detective software onyour
            devices in accordance with the terms of this EULA agreement.
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            You are permitted to load the English Detective software (for
            example a PC, laptop, mobile or tablet) under your control. You are
            responsible for ensuring your device meets the minimum requirements
            of the English Detective software.
          </p>

          <p style={{lineHeight: '24px', marginTop: '24px'}}>You are not permitted to:</p>

            <p>
              Edit, alter, modify, adapt, translate or otherwise change the
              whole or any part of the Software nor permit the whole or any part
              of the Software to be combined with or become incorporated in any
              other software, nor decompile, disassemble or reverse engineer the
              Software or attempt to do any such things
            </p>


            <p>
              Reproduce, copy, distribute, resell or otherwise use the Software
              for any commercial purpose
            </p>



            <p>
              Allow any third party to use the Software on behalf of or for the
              benefit of any third party
            </p>



            <p>
              Use the Software in any way which breaches any applicable local,
              national or international law
            </p>


            <p>
              Use the Software for any purpose that English Detective considers
              is a breach of this EULA agreement
            </p>

          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            <p style={{fontWeight: 'bold', marginTop: '24px'}}>
              Intellectual Property and Ownership
            </p>
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            Anglo Overseas Commerce Ltd, trading as English Detective, shall at
            all times retain ownership of the Software as originally downloaded
            by you and all subsequent downloads of the Software by you. The
            Software (and the copyright, and other intellectual property rights
            of whatever nature in the Software, including any modifications made
            thereto) are and shall remain the property of Anglo Overseas
            Commerce Ltd trading as English Detective.
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            English Detective reserves the right to grant licences to use the
            Software to third parties.
          </p>

          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            <p style={{fontWeight: 'bold', marginTop: '24px'}}>Termination</p>
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            This EULA agreement is effective from the date you first use the
            Software and shall continue until terminated. You may terminate it
            at any time upon written notice to English Detective.
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            It will also terminate immediately if you fail to comply with any
            term of this EULA agreement. Upon such termination, the licenses
            granted by this EULA agreement will immediately terminate and you
            agree to stop all access and use of the Software. The provisions
            that by their nature continue and survive will survive any
            termination of this EULA agreement. This EULA was created by &nbsp;

            <a href="https://www.app-privacy-policy.com/app-eula-generator/" target="_blank" rel="noreferrer" style={{ color: 'blue' }}>App EULA Template Generator from App-Privacy-Policy.com for English Detective</a>

          </p>

          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            <p style={{fontWeight: 'bold', marginTop: '24px'}}>Governing Law</p>
          </p>
          <p style={{lineHeight: '24px', marginTop: '24px'}}>
            This EULA agreement, and any dispute arising out of or in connection
            with this EULA agreement, shall be governed by and construed in
            accordance with the laws of England.
          </p>




              </Grid>
            </Grid>
          </div>



        </div>
      </div>
  }
}