import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Badge from '@material-ui/core/Badge';

import { mainColor } from '../../metadata';


export default class Contact extends React.Component {

  render() {
    return this.props.dialogReady && (
      <div style={{width: '320px', padding: '6px 0px', borderRight: '1px solid #eeeeee', overflowY: 'auto'}}>
        {this.props.dialogData.items.map(dialog => (
          <ListItem
            key={dialog._id}
            button
            selected={dialog.user_id === this.props.opponentId}
            onClick={event => this.props.selectDialog(dialog)}
          >
            <ListItemAvatar>
              <Badge
                badgeContent={dialog.unread_messages_count}
                color="secondary"
              >
                <Avatar
                  alt="Contact user avatar"
                  src={dialog.avatar}
                />
              </Badge>
            </ListItemAvatar>
            <ListItemText primary={<div style={{padding: '0px 12px 0px 0px', wordBreak: 'break-all'}}>{dialog.name}</div>} />
            <div style={{
              minWidth: '40px',
              padding: '0px 6px',
              // fontSize: '10px',
              lineHeight: '24px',
              borderRadius: '12px',
              backgroundColor: dialog.membership ? mainColor : '#c0c0c0',
              textAlign: 'center',
              color: 'white'
            }}>
              <span style={{fontSize: '12px'}}>{dialog.membership || 'free'}</span>
              {dialog.membership && <span style={{fontSize: '10px'}}>&nbsp;({dialog.usageQuery}/{dialog.countQuery})</span>}
            </div>
          </ListItem>
        ))}
      </div>
    );
  }
}
