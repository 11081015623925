const host = process.env.REACT_APP_API_HOST;

class DictionaryService {

  async searchOxfordEnglish(search) {
    try {
      const response = await fetch(host + '/dictionaries/oxford/en?search=' + search);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async searchOxfordChinese(search) {
    try {
      const response = await fetch(host + '/dictionaries/oxford/zh?search=' + encodeURI(search));
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async searchFinancialWord(query) {
    try {
      const response = await fetch(host + '/dictionaries/financial?search=' + query.search +'&offset='+query.offset);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async searchMedicalWord(query) {
    try {
      const response = await fetch(host + '/dictionaries/medical?search=' + query.search +'&offset='+query.offset);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async searchLegalWord(query) {
    try {
      const response = await fetch(host + '/dictionaries/legal?search=' + query.search +'&offset='+query.offset);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async searchIdiomsWord(query) {
    try {
      const response = await fetch(host + '/dictionaries/idioms?search=' + query.search +'&offset='+query.offset);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async searchThesaurusWord(query) {
    try {
      const response = await fetch(host + '/dictionaries/thesaurus?search=' + query.search +'&offset='+query.offset);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async getLanguages() {
    try {
      const response = await fetch(host + '/dictionaries/languages');
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async searchTranslateWord(search) {
    try {
      const response = await fetch(host + '/dictionaries/translate?'+ search);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
  async getGameWord() {
    try {
      const response = await fetch(host + '/dictionaries/word');
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async getHangmanWord() {
    try {
      const response = await fetch(host + '/dictionaries/idiom');
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async searchDictionary(word) {
    try {
      const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export default Object.freeze(new DictionaryService());
