import React from 'react';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import DictionaryHeader from './DictionaryHeader';
import LoaderElement from '../../components/LoaderElement';
import DictionaryService from '../../services/DictionaryService';
import { mainColor } from '../../metadata';
export default class GeneralDictionary extends React.Component {
  state = {
    language: 'ldoce5',
    searchWord: '',
    searchLoad: false,
    searchData: []
  };

  clearSearch = (event) => {
    this.setState({
      searchWord: '',
      searchLoad: false,
      searchData: []
    });
  };

  onSearchWord = async () => {
    if (this.state.searchWord) {
      this.setState({ searchLoad: true, searchData: [] });
      // let searchResult = {};
      // if (this.state.language === 'ldec') {
      //   searchResult = await DictionaryService.searchOxfordChinese(
      //     this.state.searchWord,
      //     this.state.language
      //   );
      // } else {
      //   searchResult = await DictionaryService.searchOxfordEnglish(
      //     this.state.searchWord,
      //     this.state.language
      //   );
      // }

      // if (this.state.searchLoad) {
      //   this.setState({
      //     searchLoad: false,
      //     searchData:
      //       searchResult && searchResult.results ? searchResult.results : []
      //   });
      // }
      try {
        const response = await DictionaryService.searchDictionary(this.state.searchWord);
        console.log(response)
        this.setState({
          searchData: response,
        })
      } finally {
        this.setState({ searchLoad: false })
      }
    }
  };

  renderResults = (result, index) => {
    return (
      <div
        key={result.id + '-' + index}
        className="anglo-card"
        style={{
          backgroundColor: '#f0f0f0',
          marginBottom: '12px',
          padding: '12px',
          borderRadius: '6px'
        }}
      >
        <div>
          <h4 style={{ fontWeight: 'bold', margin: '12px 0px' }}>
            Result {index + 1}
          </h4>
          <Divider />
        </div>
        {result.lexicalEntries.map((item, i) =>
          this.renderItems(item, i, result.word)
        )}
      </div>
    );
  };

  renderItems = (item, index, word) => {
    return (
      <div key={`category-${index}`} style={{ paddingTop: '12px' }}>
        <div
          style={{
            width: '22px',
            height: '22px',
            lineHeight: '22px',
            borderRadius: '11px',
            backgroundColor: mainColor,
            textAlign: 'center',
            color: '#f0f0f0',
            fontSize: '13px',
            display: 'inline-block'
          }}
        >
          {index + 1}
        </div>
        <span style={{ marginLeft: '6px', fontSize: '15px' }}>{word}</span>
        <i style={{ marginLeft: '6px', fontSize: '13px', color: '#666666' }}>
          {item.lexicalCategory.text}
        </i>
        {item.entries &&
          item.entries.map((entry) =>
            Object.keys(entry)
              .filter((key) =>
                ['pronunciations', 'senses', 'etymologies'].includes(key)
              )
              .map((key, index) => {
                return (
                  <div key={`${key}-${index}`} style={{ marginBottom: '12px' }}>
                    {entry[key] && (
                      <h4
                        style={{
                          fontSize: '14px',
                          marginBottom: '12px',
                          textTransform: 'capitalize'
                        }}
                      >
                        {key.split('_').join(' ')}
                      </h4>
                    )}

                    {(() => {
                      switch (key) {
                        case 'pronunciations':
                          return this.renderPronunciations(entry[key]);
                        case 'senses':
                          return this.renderSenses(entry[key]);
                        case 'etymologies':
                          return this.renderEtymologies(entry[key]);
                        default:
                          return null;
                      }
                    })()}
                  </div>
                );
              })
          )}
        {item.phrases && this.renderPhrases(item.phrases, index)}
      </div>
    );
  };

  renderPronunciations = (pronunciations) => {
    return (
      pronunciations && (
        <React.Fragment>
          {pronunciations.map((pronunciation, i1) =>
            pronunciation.dialects.map((dialect, i2) => (
              <div key={`${i1}-${i2}`} style={{ marginBottom: '12px' }}>
                <Chip
                  label={
                    dialect +
                    (pronunciation.phoneticSpelling
                      ? '(' + pronunciation.phoneticSpelling + ')'
                      : '') +
                    ':'
                  }
                  style={{ marginBottom: '6px' }}
                />
                <div>
                  <audio src={pronunciation.audioFile} controls></audio>
                </div>
              </div>
            ))
          )}
        </React.Fragment>
      )
    );
  };

  renderSenses = (senses) => {
    return (
      senses && (
        <React.Fragment>
          {senses.map((sense) =>
            Object.keys(sense)
              .filter((key) =>
                ['definitions', 'translations', 'examples'].includes(key)
              )
              .map((key, index) => (
                <div key={key} style={{ marginBottom: '12px' }}>
                  <Chip
                    label={
                      <span style={{ textTransform: 'capitalize' }}>
                        {key.split('_').join(' ')}
                      </span>
                    }
                    style={{ marginBottom: '6px' }}
                  />
                  <div>
                    {(() => {
                      switch (key) {
                        case 'definitions':
                          return (
                            <span
                              key={`${key}-${index}`}
                              className="anglo-first-letter-capitalize"
                            >
                              {`${index + 1}. ${sense[key].join('\n')}`}
                            </span>
                          );
                        case 'translations':
                          return (
                            <span
                              key={`${key}-${index}`}
                              className="anglo-first-letter-capitalize"
                            >
                              {`${index + 1}. ${sense[key]}`}
                            </span>
                          );
                        case 'examples':
                          return sense[key].map((example, i) => (
                            <div key={`${key}-${index}-${i}`}>
                              <code className="anglo-first-letter-capitalize">
                                {`${i + 1}. ${example.text.trim("'")}`}
                              </code>
                            </div>
                          ));
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              ))
          )}
        </React.Fragment>
      )
    );
  };

  renderPhrases = (phrases, index) => {
    return (
      <div key={`phrases-${index}`} style={{ marginBottom: '12px' }}>
        <h4
          style={{
            fontSize: '14px',
            marginBottom: '12px',
            textTransform: 'capitalize'
          }}
        >
          Phrases
        </h4>
        <ul
          style={{
            listStyle: 'decimal',
            margin: '0px',
            padding: '0px',
            lineHeight: '24px',
            marginLeft: '48px'
          }}
        >
          {phrases.map((phrase, idx) => {
            return <li key={`${phrase.id}-${idx}`}>{phrase.text}</li>;
          })}
        </ul>
      </div>
    );
  };

  renderEtymologies = (etymologies) => {
    return (
      etymologies && (
        <React.Fragment>
          {etymologies.map((etymology, i) => (
            <div key={`etymologies-${i}`}>
              <span className="anglo-first-letter-capitalize">
                {`${i + 1}. ${etymology}`}
              </span>
            </div>
          ))}
        </React.Fragment>
      )
    );
  };

  render() {
    return (
      <React.Fragment>
        <DictionaryHeader
          bilingual={true}
          word={this.state.searchWord}
          language={this.state.language}
          onWordChange={(value) => this.setState({ searchWord: value })}
          onLanguageChange={(value) => this.setState({ language: value })}
          onClear={this.clearSearch}
          onSearch={this.onSearchWord}
        />
        <div className="flex-1" style={{ overflow: 'auto' }}>
          <LoaderElement
            loading={this.state.searchLoad}
            style={{ textAlign: 'left', padding: '24px' }}
          >
            {0 === this.state.searchData.length
              ? 'No results found!'
              : !Array.isArray(this.state.searchData)
              ? this.state.searchData.title
              : <ul style={{ margin: 0, padding: 0, listStyle: "none"}}>
                {this.state.searchData.map((i, j) => <li key={j} style={{backgroundColor: "#F0F0F0", marginBottom: "6px", padding: "6px 12px"}}>
                  <div style={{display: "flex"}}>
                    <div style={{ width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#DC0000", textAlign: "center", color: "white", marginBottom: "12px", marginRight: "12px"}}>
                      {j + 1}
                    </div>
                    <div style={{fontWeight: "bold", size: "20px"}}>
                      <a href={i.sourceUrls[0]} target="_blank">{i.word}</a>
                    </div>
                  </div>
                  <div style={{marginBottom: "8px"}}>
                    <div style={{marginRight: "12px", fontWeight: "bold", marginBottom: "8px"}}>Phonetics:</div>
                    <ul>
                      {i.phonetics.map((k, kj) => <li key={kj} style={{marginBottom: "8px", display: "flex", justifyContent: "start", alignItems: "center"}}>
                        <a href={k.sourceUrl} target="_blank">[{k.text}]</a>
                        <audio controls>
                          <source src={k.audio} type="audio/mpeg" />
                        </audio>
                      </li>)}
                    </ul>
                  </div>
                  <div style={{marginBottom: "8px"}}>
                    <div style={{marginRight: "12px", fontWeight: "bold", marginBottom: "8px"}}>Meanings:</div>
                    <ul>
                      {i.meanings.map((k, kj) => <li key={kj} style={{marginBottom: "24px"}}>
                        <div style={{marginBottom: "8px"}}>
                          <label style={{textDecoration: "underline"}}>Part of speech:</label>
                          <span style={{fontStyle: "italic", marginLeft: "8px"}}>{k.partOfSpeech}</span>
                        </div>

                        <div style={{marginBottom: "8px"}}>
                          <div style={{textDecoration: "underline", marginBottom: "8px"}}>Definitions:</div>
                          <ul>
                          {k.definitions.map((l, lj) => <li key={lj} style={{marginBottom: "8px"}}>

                            <div style={{marginBottom: "8px"}}>
                              {l.definition}</div>
                            
                            {l.antonyms.length > 0 && <div style={{marginBottom: "8px"}}>
                              <span style={{textDecoration: "underline"}}>Antonyms:</span> {l.antonyms.join(", ")}</div>}
                            {l.synonyms.length > 0 && <div style={{marginBottom: "8px"}}>
                              <span style={{textDecoration: "underline"}}>Synonyms:</span> {l.synonyms.join(", ")}</div>}

                          </li>) }
                          </ul>
                        </div>


                        {k.antonyms.length > 0 && <div style={{marginBottom: "8px"}}>
                          <span style={{textDecoration: "underline"}}>Antonyms:</span> {k.antonyms.join(", ")}</div>}
                        {k.synonyms.length > 0 && <div style={{marginBottom: "8px"}}>
                          <span style={{textDecoration: "underline"}}>Synonyms:</span> {k.synonyms.join(", ")}</div>}
                      </li>)}
                    </ul>
                  </div>
                </li>)}
              </ul>
            }
          </LoaderElement>
        </div>
      </React.Fragment>
    );
  }
}
