import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import {
  ProfileOutlined,
  DollarCircleOutlined,
  BookOutlined,
  MedicineBoxOutlined,
  ReadOutlined,
  TranslationOutlined,
  TrophyOutlined,
  RocketOutlined,
  CoffeeOutlined,
  // AudioOutlined,
  CustomerServiceOutlined,
  CodeOutlined,
  ExperimentOutlined
} from '@ant-design/icons';

import GeneralDictionary from './GeneralDictionary';
import NormalDictionary from './NormalDictionary';
import PoetryCorner from './PoetryCorner';
import GameVowels from './GameVowels';
import GameSpell from './GameSpell';
import GameHangman from './GameHangman';
import Translate from './Translate';
// import DictionaryService from '../../services/DictionaryService';
// import AudioBooks from './AudioBooks';

export default class Detective extends React.Component {
  tabs = [
    {
      label: 'Oxford English Dictionary',
      icon: <ProfileOutlined style={{ paddingLeft: '6px' }} />,
      component: <GeneralDictionary />
    },
    {
      label: 'Financial Dictionary',
      icon: <DollarCircleOutlined style={{ paddingLeft: '6px' }} />,
      component: <NormalDictionary type="financialDictionary" />
    },
    {
      label: 'Medical Dictionary',
      icon: <MedicineBoxOutlined style={{ paddingLeft: '6px' }} />,
      component: <NormalDictionary type="medicalDictionary" />
    },
    {
      label: 'Legal Dictionary',
      icon: <TrophyOutlined style={{ paddingLeft: '6px' }} />,
      component: <NormalDictionary type="legalDictionary" />
    },
    {
      label: 'Translate',
      icon: <TranslationOutlined style={{ paddingLeft: '6px' }} />,
      component: <Translate />
    },
    {
      label: 'Idioms',
      icon: <ReadOutlined style={{ paddingLeft: '6px' }} />,
      component: <NormalDictionary type="idiomsDictionary" />
    },
    {
      label: 'Thesaurus',
      icon: <BookOutlined style={{ paddingLeft: '6px' }} />,
      component: <NormalDictionary type="thesaurusDictionary" />
    },
    {
      label: 'Games',
      icon: <RocketOutlined style={{ paddingLeft: '6px' }} />,
      opendKey: 'openGames',
      children: [
        {
          label: 'Vowels (Basic)',
          icon: <CustomerServiceOutlined style={{ paddingLeft: '6px' }} />,
          component: <GameVowels type="thesaurusDictionary" />
        },
        {
          label: 'Spell (Intermediate)',
          icon: <CodeOutlined style={{ paddingLeft: '6px' }} />,
          component: <GameSpell type="thesaurusDictionary" />
        },
        {
          label: 'Hangman (Advanced)',
          icon: <ExperimentOutlined style={{ paddingLeft: '6px' }} />,
          component: <GameHangman type="thesaurusDictionary" />
        }
      ]
    },
    {
      label: 'Poetry Corner',
      icon: <CoffeeOutlined style={{ paddingLeft: '6px' }} />,
      component: <PoetryCorner />
    },
    // {
    //   label: 'Audio Books',
    //   icon: <AudioOutlined style={{ paddingLeft: '6px' }} />,
    //   component: <AudioBooks />
    // }
  ];

  state = {
    tabLabel: '',
    tabComponent: '',
    openGames: false
  };

  // componentDidMount() {
  //   DictionaryService.searchFinancialWord('money')
  // }

  handleTabClick = (item) => {
    if(item.children) {
      this.setState({
        tabLabel: item.label,
        [item.opendKey] : !this.state[item.opendKey]
      });
    }
    else{
      this.setState({
        tabLabel: item.label,
        tabComponent: item.component
      });
    }
  };

  render() {
    return (
      <div className="anglo-container" style={{ marginTop: '24px' }}>
        <div
          className="flex-column"
          style={{
            borderRadius: '4px',
            height: '800px',
            backgroundColor: '#f0f0f0'
          }}
        >
          <Hidden mdUp>
            <div style={{ padding: '12px 26px' }}>
              <h3
                style={{
                  lineHeight: '40px',
                  fontWeight: '600',
                  margin: '0px 12px 0px 0px'
                }}
              >
                {this.state.tabLabel}
              </h3>
            </div>
          </Hidden>
          <div className="flex-1 flex" style={{ height: '736px' }}>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              style={{ borderRight: '1px solid #eeeeee' }}
              className="anglo-tabs"
            >
              {this.tabs.map((item) => (
                <React.Fragment key={item.label}>
                  <ListItem
                    button
                    selected={this.state.tabLabel === item.label}
                    onClick={() => this.handleTabClick(item)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={
                        <Hidden smDown>
                          <span
                            style={{
                              marginLeft: '6px',
                              width: '208px',
                              color: '#696969',
                              fontWeight: 'bold',
                              display: 'inline-block'
                            }}
                          >
                            {item.label}
                          </span>
                        </Hidden>
                      }
                    />
                    {item.children &&
                      (this.state[item.opendKey] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                  </ListItem>
                  {item.children &&
                    item.children.map((child) => (
                      <Collapse
                        key={child.label}
                        in={this.state[item.opendKey]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            style={{ paddingLeft: '32px' }}
                            selected={this.state.tabLabel === child.label}
                            onClick={() => this.handleTabClick(child)}
                          >
                            <ListItemIcon>{child.icon}</ListItemIcon>
                            <ListItemText
                              primary={
                                <Hidden smDown>
                                  <span
                                    style={{
                                      marginLeft: '6px',
                                      width: '188px',
                                      color: '#696969',
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {child.label}
                                  </span>
                                </Hidden>
                              }
                            />
                          </ListItem>
                        </List>
                      </Collapse>
                    ))}
                </React.Fragment>
              ))}
            </List>
            <Paper
              style={{ padding: '8px 24px' }}
              className="flex-1 flex-column"
            >
              {this.state.tabComponent}
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
