import React from 'react';
import { Link } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import IconButton from '@material-ui/core/IconButton';

import { mainColor, hightLightColor } from '../../metadata';
import TitleElement from '../../components/TitleElement';
import LoaderElement from '../../components/LoaderElement';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import TopicModal from './TopicModal';
import HttpService from '../../services/HttpService';
import StorageService from '../../services/StorageService';


export default class Blog extends React.Component {

  state = {
    data: [],
    count: 0,
    dataLoading: true,
    visible: false,
    current: {},
    dialog: false,
    topicId: "",
    filters: {
      page: 1,
      size: 10
    }
  };

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    this.setState({dataLoading: true})
    try {
      const topics = await HttpService.fetchTopics(this.state.filters.page, this.state.filters.size)
      this.setState({data: topics.data, count: topics.count})
    } finally {
      this.setState({dataLoading: false})
    }
  }

  handleChange = (e, page) => {
    this.setState({filters: {
      ...this.state.filters,
      page,
    }}, this.initialize);
  }

  closeModal = () => {
    this.setState({visible: false})
  }

  changeCurrent = item => {
    this.setState({
      current: {
        ...this.state.current,
        ...item
      }
    })
  }

  handleOk = async () => {
    if (this.state.current.topicId) {
      await HttpService.updateTopics(this.state.current)
    } else {
      await HttpService.createTopics(this.state.current)
    }
    this.setState({visible: false})
    this.initialize()
  }

  handleDelete = async () => {
    await HttpService.deleteTopics(this.state.topicId)
    this.setState({dialog: false})
    this.initialize()
  }

  render() {
    return (
      <div className="anglo-container" style={{marginTop: '24px'}}>
        <Dialog
          open={this.state.dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure to delete this item?</DialogTitle>
          <DialogActions style={{padding: "12px 24px"}}>
            <Button onClick={this.handleDelete} color="primary">
              Ok
            </Button>
            <Button onClick={() => this.setState({dialog: false, topicId: ""})} color="" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <TopicModal
          visible={this.state.visible}
          current={this.state.current}
          handleOk={this.handleOk}
          changeCurrent={this.changeCurrent}
          handleClose={this.closeModal}
        />
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', minHeight: '800px'}} className="flex-column">
          <TitleElement
            title="English Detective Blog"
            titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
            description={StorageService.isAdmin() &&
              <Button variant="contained"
                color="secondary"
                style={{
                  width: '160px',
                  textTransform: 'none',
                  padding: '5px 12px',
                  fontSize: '16',
                  backgroundColor: mainColor,
                  marginRight: '12px'}}
                disableRipple
                onClick={() => this.setState({current: {}, visible: true})}
              >
              Add New Topic
            </Button>}
            descriptionStyle={{margin: '0px 0px 36px', textAlign: 'center'}}
          />
          <LoaderElement loading={this.state.dataLoading}>
            <List style={{padding: '12px 36px'}}>
              {this.state.data.map(item =>
              <React.Fragment key={item.topicId}>
                <ListItem>
                  <div>
                    <Link style={{fontSize: '20px', fontWeight: 'bold'}} to={`/topic?topicId=${item.topicId}`}>{item.title}</Link>
                    <ListItemText style={{margin: "6px 0px"}}
                      primary={
                      <div style={{height: '50px', overflow: 'hidden'}}
                        dangerouslySetInnerHTML={{__html: item.content}}
                      ></div>}
                    />
                  </div>
                  {StorageService.isAdmin() &&
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => this.setState({dialog: true, topicId: item.topicId})}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="update" onClick={() => this.setState({current: item, visible: true})}>
                        <UpdateIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  }
                </ListItem>
                <Divider />
              </React.Fragment>)}
            </List>
            <Pagination count={Math.ceil(this.state.count / this.state.filters.size)}
              page={this.state.filters.page}
              onChange={this.handleChange}
              style={{padding: '12px 36px 24px', justifyContent: 'center'}}
            />
          </LoaderElement>
        </div>
      </div>
    );
  }
}
