import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { PlusCircleOutlined } from '@ant-design/icons';
import DictionaryService from '../../services/DictionaryService';

const useStyles = makeStyles({
  newWordBtn: {
    color: '#28a745',
    borderColor: '#28a745',
    marginRight: '15px'
  },
  btnGreen: {
    color: '#ffffff',
    borderColor: '#28a745',
    backgroundColor:'#28a745'
  },
  btnRed: {
    color: '#ffffff',
    borderColor: '#dc3545',
    backgroundColor:'#dc3545'
  },
});

export default function GameSpell() {
  const classes = useStyles();
  const answerState = useMemo(() => ({ init: 0, right: 1, mistake: 2 }), []);
  const [category, setCategory] = useState('');
  const [word, setWord] = useState('');
  const [answering, setAnswering] = useState([]);
  const [successLetter, setSuccessLetter] = useState([]);
  const [reshuffle, setReshuffle] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const getWord = async () => {
    const result = await DictionaryService.getGameWord();
    if (result) {
      setCategory(result.category);
      setWord(result.word);
    }
  };

  useEffect(() => {
    getWord();
  }, []);

  const onShuffle = useCallback(
    (array) => {
      let oldElement;
      for (let i = array.length - 1; i > 0; i--) {
        let rand = Math.floor(Math.random() * (i + 1));
        oldElement = array[i];
        if (i !== rand) {
          array[i].isRight = answerState.mistake;
          array[rand].isRight = answerState.mistake;
        }
        array[i] = array[rand];
        array[rand] = oldElement;
      }
      return array;
    },
    [answerState]
  );

  useEffect(() => {
    const charArray = word.split('');
    let successArray = [];
    let reshuffleArray = [];
    charArray.forEach((char, index) => {
      successArray.push({
        val: char,
        index: index,
        isRight: answerState.right
      });
      reshuffleArray.push({
        val: char,
        index: index,
        isRight: answerState.right
      });
    });
    setSuccessLetter(successArray);
    setReshuffle(onShuffle(reshuffleArray));
    setAnswering([]);
    setIsVisible(false);
  }, [word, answerState, onShuffle]);

  const renderLettersGroup = () => {
    return (
      <div style={{ marginTop: '30px' }}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {reshuffle.length &&
            reshuffle.map((vowel, index) => {
              return (
                <Button
                  key={`letter-${index}`}
                  style={{ textTransform: 'lowercase' }}
                  onClick={() => onAnswer(index)}
                >
                  {vowel.val}
                </Button>
              );
            })}
        </ButtonGroup>
      </div>
    );
  };

  const renderAnswerGroup = () => {
    return (
      <div style={{ marginTop: '30px' }}>
        <ButtonGroup
          color="primary"
          aria-label="primary button group"
          style={{
            color: '#28a745',
            borderColor: '#28a745'
          }}
        >
          {answering.length &&
            answering.map((vowel, index) => {
              return (
                <Button
                  key={'answer' + index}
                  style={{ textTransform: 'lowercase' }}
                  className={vowel.isRight === answerState.right ? classes.btnGreen: classes.btnRed}
                  onClick={() => onCancel(index)}
                >
                  {vowel.val}
                </Button>
              );
            })}
        </ButtonGroup>
      </div>
    );
  };

  const onAnswer = (selectedIndex) => {
    let answerItem = reshuffle[selectedIndex];
    let reshuffleCopy = [];
    reshuffle.forEach((letter, index) => {
      reshuffleCopy.push({
        val: letter.val,
        index: letter.index,
        isRight: letter.isRight
      });
    });
    reshuffleCopy.splice(selectedIndex, 1);
    setReshuffle(reshuffleCopy);

    let answerCopy = [];
    answering.forEach((letter, index) => {
      answerCopy.push({
        val: letter.val,
        index: index,
        isRight: letter.isRight
      });
    });
    answerCopy.push(answerItem);

    answerCopy.forEach((letter, index) => {
      let answeringArray = successLetter.filter(
        (answer) => answer.index === index
      );
      if (answeringArray.length > 0 && answeringArray[0].val === letter.val) {
        letter.isRight = answerState.right;
      } else {
        letter.isRight = answerState.mistake;
      }
    });
    setAnswering(answerCopy);
    solved(answerCopy);
  };

  const onCancel = selectedIndex => {
    let answerItem = answering[selectedIndex];
    let answerCopy = [];
    answering.forEach((letter,index) =>{
      answerCopy.push({
          val:letter.val,
          index:letter.index,
          isRight:letter.isRight
      });
    })
    answerCopy.splice(selectedIndex,1);
    setAnswering(answerCopy);

    let reshuffleCopy = [];
    reshuffle.forEach((letter,index) =>{
      reshuffleCopy.push({
          val:letter.val,
          index:letter.index,
          isRight:letter.isRight
      });
    })
    reshuffleCopy.push(answerItem);
    setReshuffle(reshuffleCopy);
    solved(answerCopy);
  }

  const solved = (answerArray) => {
    let mistakeList = answerArray.filter(
      (letter) => letter.isRight === answerState.mistake
    );
    if (
      mistakeList.length === 0 &&
      answerArray.length === successLetter.length
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ padding: '36px 16px' }}>
        <Grid item xs={12} md={12}>
          <Paper
            className="flex-column"
            style={{
              padding: '10px 20px',
              textAlign: 'center',
              minHeight: '30px'
            }}
          >
            <p style={{ fontSize: '16px' }}>
              Rearrange the anagram to spell a word. Click the letters in the
              correct order to add them to the answer. Click a letter in the
              answer to remove it again.
            </p>
          </Paper>
        </Grid>
      </Grid>
      <div
        className="anglo-card"
        style={{
          backgroundColor: '#f0f0f0',
          marginBottom: '12px',
          padding: '12px',
          borderRadius: '6px'
        }}
      >
        <h4 style={{ fontWeight: 'bold', margin: '12px 0px' }}>
          Word category: {category}
        </h4>
        <Divider />

        <div style={{ marginTop: '10px', padding: '15px' }}>
          <h4 style={{ fontWeight: 'bold', margin: '12px 0px' }}>Letters</h4>
          {isVisible && (
            <div
              style={{
                marginTop: '30px',
                fontWeight: 'bold',
                fontSize: '20px',
                color: '#a4c936'
              }}
            >
              <span style={{ paddingBottom: '10px' }}>
                Congratulations, you solved it!
              </span>
              {word.definition && <span>Definition: {word.definition} </span>}
            </div>
          )}
          {reshuffle && renderLettersGroup()}
          <h4 style={{ fontWeight: 'bold', margin: '12px 0px' }}>Answer</h4>
          {renderAnswerGroup()}
          <div style={{ marginTop: '30px' }}>
            <Button
              variant="outlined"
              className={classes.newWordBtn}
              onClick={() => getWord()}
            >
              <PlusCircleOutlined style={{ paddingRight: '5px' }} />
              New
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
