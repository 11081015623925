import StorageService from '../services/StorageService';
import ConnectyCubeService from '../services/ConnectyCubeService';

class MessageStore {

  state = {
    dialogData: {},
    dialogReady: false,
    chatData: {},
    chatReady: false,

    contacts: {
      limit: 100,
      skip: 0,
      total_entries: 0,
      items: [],
    },
    currentDialogId: 0,
  };

  recoverMessageType = Symbol('recoverMessageType');
  recoverMessage = () => ({
    type: this.recoverMessageType,
  });

  initializeMessageType = Symbol('initializeMessageType');
  initializeMessage = dispatch => async () => {
    await ConnectyCubeService.createSession({
      login: StorageService.getItem('connectyCubeUsername'),
      password: StorageService.getItem('connectyCubePassword'),
    });
    await ConnectyCubeService.createChat({
      userId: StorageService.getItem('connectyCubeUserId'),
      password: StorageService.getItem('connectyCubePassword'),
    });

    ConnectyCubeService.receiveMessage(async (connectyCubeUserId, message) => {
      
    });

    // ConnectyCubeService.receiveMessage(async (connectyCubeUserId, message) => {
    //   if (connectyCubeUserId === this.state.opponentId) {
    //     this.appendMessage(this.state.opponentId, StorageService.getItem('connectyCubeUserId'), message.body);
    //     ConnectyCubeService.setMessageRead(message.id, this.dialogId, StorageService.getItem('connectyCubeUserId'));
    //     await this.initializeUserDialog();
    //   } else {
    //     if (this.state.dialogData && this.state.dialogData.items && this.state.dialogData.items.length > 0) {
    //       const dialogIndex = this.state.dialogData.items.findIndex(dialog => dialog.user_id === connectyCubeUserId);
    //       if (-1 === dialogIndex) {
    //         await this.fetchDialog();
    //       } else {
    //         const targetDialog = this.state.dialogData.items[dialogIndex];
    //         targetDialog.unread_messages_count += 1;
    //         this.setState({
    //           dialogData: {
    //             ...this.state.dialogData,
    //             items: [
    //               targetDialog,
    //               ...this.state.dialogData.items.slice(0, dialogIndex),
    //               ...this.state.dialogData.items.slice(1 + dialogIndex),
    //             ],
    //           }
    //         });
    //       }
    //     }
    //   }
    // });

  };

  // initializeMessageType = Symbol('initializeMessageType');
  // initializeMessage = () => ({
  // });
  // fetchDialogType = Symbol('fetchDialogType');
  // fetchDialog = dispatch => async () => {

  // };

  // createDialogType = Symbol('createDialogType');
  // createDialog = () => {

  // }

  reducer = (state = this.state, action) => {
    switch (action.type) {
      case this.recoverMessageType:
        return this.state;
      default:
        return state;
    }
  }
}

export default Object.freeze(new MessageStore());
