import React, { Component } from 'react';
import DictionaryHeader from './DictionaryHeader';
import LoaderElement from '../../components/LoaderElement';
import DictionaryService from '../../services/DictionaryService';

export default class Translate extends Component {
  componentDidMount = async () => {
    const results = await DictionaryService.getLanguages();
    this.setState({ languages: results.languages });
  };

  state = {
    languages: [],
    fromLanguage: 'en',
    toLanguage: 'zh-CHS',
    searchWord: '',
    searchLoad: false,
    searchData: []
  };

  clearSearch = (event) => {
    this.setState({
      searchWord: '',
      searchLoad: false,
      searchData: []
    });
  };

  onSearchWord = async () => {
    if(this.state.searchWord) {
      this.setState({ searchLoad: true });
      const searchResult = await DictionaryService.searchTranslateWord(
        `search=${this.state.searchWord}&from=${this.state.fromLanguage}&to=${this.state.toLanguage}`
      );
      if (this.state.searchLoad) {
        this.setState({
          searchLoad: false,
          searchData: searchResult && searchResult.result ? searchResult.result : []
        });
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <DictionaryHeader
          multiple={true}
          word={this.state.searchWord}
          languages={this.state.languages}
          fromLanguage={this.state.fromLanguage}
          toLanguage={this.state.toLanguage}
          onWordChange={(value) => this.setState({ searchWord: value })}
          onFromLanguageChange={(value) =>
            this.setState({ fromLanguage: value })
          }
          onToLanguageChange={(value) => this.setState({ toLanguage: value })}
          onClear={this.clearSearch}
          onSearch={this.onSearchWord}
        />
        <div className="flex-1" style={{ overflow: 'auto' }}>
          <LoaderElement
            loading={this.state.searchLoad}
            style={{ textAlign: 'left', padding: '24px' }}
          >
            {0 === this.state.searchData.length ? (
              'No results found!'
            ) : (
              <div
                className="anglo-card"
                style={{
                  backgroundColor: '#f0f0f0',
                  marginBottom: '12px',
                  padding: '12px',
                  borderRadius: '6px'
                }}
              >
                {this.state.searchData}
              </div>
            )}
          </LoaderElement>
        </div>
      </React.Fragment>
    );
  }
}
