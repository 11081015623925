import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PlusCircleOutlined, BulbOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';
import DictionaryService from '../../services/DictionaryService';

import * as p1 from '../../assets/hangman/0.png';
import * as p2 from '../../assets/hangman/1.png';
import * as p3 from '../../assets/hangman/2.png';
import * as p4 from '../../assets/hangman/3.png';
import * as p5 from '../../assets/hangman/4.png';
import * as p6 from '../../assets/hangman/5.png';
import * as p7 from '../../assets/hangman/6.png';

const useStyles = makeStyles({
  textLayout: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  wordLayout: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginRight: '15px'
  },
  wordBox: {
    margin: '1px',
    border: '1px solid',
    borderRadius: '3px',
    borderColor: '#DC0000',
    borderWidth: '1px',
    width: '24px',
    height: '24px',
    textAlign: 'center'
  },
  newWordBtn: {
    color: '#28a745',
    borderColor: '#28a745',
    marginRight: '15px'
  },
  hintWordBtn: {
    color: '#dc3545',
    borderColor: '#dc3545',
    marginRight: '15px'
  }
});

export default function GameHangman() {
  const classes = useStyles();
  const times = 6;
  const [idiom, setIdiom] = useState(null);
  const [wrongTimes, setWrongTimes] = useState(0);
  const [match, setMatch] = useState(false);
  const [definition, setDefinition] = useState('');
  const [letters, setLetters] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showDefinition, setShowDefinition] = useState(false);
  const images = [p1, p2, p3, p4, p5, p6, p7];

  const getWord = async () => {
    const result = await DictionaryService.getHangmanWord();
    if (result) {
      setDefinition(result.definition);
      setIdiom(result.phrase);
      setShowDefinition(false);
    }
  };

  useEffect(() => {
    getWord();
    let letKey = [];
    for (var i = 65; i < 91; i++) {
      letKey.push(i);
    }
    setLetters(letKey);
  }, []);

  useEffect(() => {
    if (idiom && idiom.length > 0) {
      const idioms = idiom.trim().toLowerCase().split('');
      let match = true;
      idioms.forEach((element) => {
        let flag = /^[a-z]+$/.test(element);
        if (flag) {
          if (selectedLetters.indexOf(element.toUpperCase()) === -1) {
            match = false;
          }
        }
      });
      setMatch(match);
    }
  }, [idiom, selectedLetters]);

  const renderWordContent = (word, idx) => {
    const wordArr = word.split('');
    return (
      <div key={`word-${idx}`} className={classes.wordLayout}>
        {wordArr.map((letter, i) => {
          return getLetterBox(letter, i);
        })}
      </div>
    );
  };

  const getLetterBox = (letter, i) => {
    const flag = /^[a-z]+$/.test(letter.toLowerCase());

    return flag ? (
      <div key={`letter-${i}`} className={classes.wordBox}>
        {selectedLetters.indexOf(letter.toUpperCase()) !== -1 && (
          <Typography
            variant="body1"
            component="span"
            style={{
              textAlignVertical: 'center',
              textAlign: 'center',
              fontSize: '20px',
              lineHeight: '24px'
            }}
          >
            {letter}
          </Typography>
        )}
      </div>
    ) : (
      <Typography variant="body1" component="span" style={{ fontSize: '20px' }}>
        {letter}
      </Typography>
    );
  };

  const renderKeyboard = (i) => {
    const letter = String.fromCharCode(65 + i);
    return (
      <Button
        key={`${letter}-${i}`}
        variant="contained"
        size="small"
        style={{
          margin: '3px',
          color: '#ffffff',
          backgroundColor:
            selectedLetters.indexOf(letter) !== -1 ? '#545b62' : '#6c757d'
        }}
        onClick={() => onSelectLetter(letter)}
      >
        {letter}
      </Button>
    );
  };

  const onSelectLetter = (letter) => {
    if (match || wrongTimes >= times) {
      return;
    }
    if (selectedLetters.indexOf(letter) === -1) {
      let l = Object.assign([], selectedLetters);
      l.push(letter);
      setSelectedLetters(l);
      checkSelectedLetter(letter);
    }
    setRefresh(!refresh);
  };

  const checkSelectedLetter = (letter) => {
    if (idiom && idiom.toUpperCase().indexOf(letter) === -1) {
      let t = wrongTimes;
      ++t;
      setWrongTimes(t);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ padding: '36px 16px' }}>
        <Grid item xs={12} md={12}>
          <Paper
            className="flex-column"
            style={{
              padding: '10px',
              textAlign: 'center',
              minHeight: '30px'
            }}
          >
            <p style={{ fontSize: '16px' }}>
              Click or type the letters to find the idiom before you run out of
              your 6 guesses.
            </p>
          </Paper>
        </Grid>
      </Grid>
      <div
        className="anglo-card"
        style={{
          backgroundColor: '#f0f0f0',
          borderRadius: '6px'
        }}
      >
        <Grid container spacing={2} style={{ padding: '30px 15px' }}>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: '10px' }}>
              <div className={classes.textLayout}>
                {idiom &&
                  idiom.split(' ').map((word, idx) => {
                    return renderWordContent(word, idx);
                  })}
              </div>
              {showDefinition && (
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  <p style={{ fontSize: '16px' }}>Definition: {definition}</p>
                </div>
              )}
              {match && (
                <div
                  style={{
                    marginTop: '20px',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: '#a4c936',
                    textAlign: 'center'
                  }}
                >
                  <span style={{ paddingBottom: '10px' }}>
                    <SmileOutlined /> Congratulations, you guessed the idiom.
                  </span>
                </div>
              )}
              {wrongTimes >= times && (
                <div
                  style={{
                    marginTop: '20px',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: '#dc3545',
                    textAlign: 'center'
                  }}
                >
                  <span style={{ paddingBottom: '10px' }}>
                    <FrownOutlined /> Sorry, you have made too many incorrect
                    guesses. The idiom was {idiom}
                  </span>
                </div>
              )}
              <div className={classes.textLayout}>
                {letters &&
                  letters.map((word, idx) => {
                    return renderKeyboard(idx);
                  })}
              </div>
              <div style={{ marginTop: '30px', textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  className={classes.newWordBtn}
                  onClick={() => {
                    setSelectedLetters([]);
                    setWrongTimes(0);
                    setMatch(false);
                    setRefresh(!refresh);
                    getWord();
                  }}
                >
                  <PlusCircleOutlined style={{ paddingRight: '5px' }} />
                  New
                </Button>
                <Button
                  variant="outlined"
                  className={classes.hintWordBtn}
                  onClick={() => setShowDefinition(true)}
                >
                  <BulbOutlined style={{ paddingRight: '5px' }} />
                  Define
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: '10px' }}>
              <div style={{ margin: '20px 0px', textAlign: 'center' }}>
                <img
                  src={images[wrongTimes].default}
                  alt="wrong times"
                  style={{
                    width: '300px',
                    height: '300px'
                  }}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
