import React from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { mainColor } from '../../metadata';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Modal from '@material-ui/core/Modal';

export default class TopicModal extends React.Component {

  render() {
    return (
      <Modal
        open={this.props.visible}
        onClose={this.props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            top: '120px',
            left: '50%',
            width: '1080px',
            maxWidth: '100%',
            transform: `translate(-50%, -0%)`,
            outline: '0px',
            border: '1px solid #f0f0f0',
            borderRadius: '3px',
            height: "720px"
          }}
        >
          <div style={{
            padding: '24px',
            display: "flex",
            flexDirection: 'column',
            height: '100%',
            boxSizing: "border-box",
            overflow: 'hidden'
          }}>
            <div className="flex-1 flex-column" style={{overflow: "auto"}}>
              <TextField label="Title" value={this.props.current.title}
                onChange={e => this.props.changeCurrent({title: e.target.value})}
                style={{width: '100%', marginBottom: '12px'}}
              />
              <CKEditor
                editor={ ClassicEditor }
                config={{toolbar: [
                    'heading', '|',
                    'fontfamily', 'fontsize', '|',
                    'alignment', '|',
                    'fontColor', 'fontBackgroundColor', '|',
                    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                    'link', '|',
                    'outdent', 'indent', '|',
                    'bulletedList', 'numberedList', 'todoList', '|',
                    'code', 'codeBlock', '|',
                    'insertTable', '|',
                    'blockQuote', '|',
                    'undo', 'redo'
                ]}}
                data={this.props.current.content}
                onChange={ ( event, editor ) => {
                  const content = editor.getData();
                  this.props.changeCurrent({content})
                }}
              />
            </div>
            <div style={{textAlign: 'center', paddingTop: '12px'}}>
              <Button variant="contained"
                color="secondary"
                style={{
                  width: '160px',
                  textTransform: 'none',
                  padding: '5px 12px',
                  fontSize: '16',
                  backgroundColor: mainColor,
                  marginRight: '12px'}}
                disableRipple
                onClick={this.props.handleOk}
              >
                Ok
              </Button>
              <Button variant="contained"
                color="secondary"
                style={{
                  width: '160px',
                  textTransform: 'none',
                  padding: '5px 12px',
                  fontSize: '16',
                  color: '#232323',
                  backgroundColor: 'white',
                  marginRight: '12px'}}
                disableRipple
                onClick={this.props.handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}



