import React from 'react';

import LoaderElement from '../../components/LoaderElement';
import HttpService from '../../services/HttpService';
import StorageService from '../../services/StorageService';


export default class WechatLogin extends React.Component {

  state = {
    description: 'Authorizing ...',
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const query = this.props.location.search.split('?')[1].split('&').map(item => {
      const itemArray = item.split('=');
      return {
        key: itemArray[0],
        value: itemArray[1],
      };
    });
    if (!query.some(item => item.key === 'code')) {
      this.setState({
        description: 'Login failed!'
      });
    } else {
      const params = {
        code: query.find(item => item.key === 'code').value,
        state: query.find(item => item.key === 'state').value,
      };
      try {
        const wechatLoginResult = await HttpService.wechatAuthorization(params);
        // console.log(wechatLoginResult);
        StorageService.setItem('wechatConnectyCubeUsername', wechatLoginResult.connectyCubeUsername);
        StorageService.setItem('wechatConnectyCubePassword', wechatLoginResult.connectyCubePassword);
        StorageService.setItem('wechatDisplayName', wechatLoginResult.displayName);
        StorageService.setItem('wechatAvatar', wechatLoginResult.avatar);
        StorageService.setItem('wechatUserExists', wechatLoginResult.exists);
        window.close();
      } catch (error) {
        this.setState({description: 'WeChat authorization failed!'});
      }
    }
  }

  render() {
    return (
      <div style={{height: '629px', backgroundColor: '#f0f0f0', padding: '24px', borderRadius: '6px'}}>
        <LoaderElement loading={true} loadingText={this.state.description} />
      </div>
    );
  }
}
