import ConnectyCube from 'connectycube/dist/connectycube.min';
// import StorageService from './StorageService';
import * as userAvatar from '../assets/user.png';

class ConnectyCubeService {

  connectyCubeClient = ConnectyCube;
  mediaParams = {
    audio: true,
    video: true,
    options: {
      muted: true,
      mirror: true,
    },
  };

  constructor() {
    const CREDENTIALS = {
      appId: process.env.REACT_APP_CONNECTYCUBE_APP_ID,
      authKey: process.env.REACT_APP_CONNECTYCUBE_AUTH_KEY,
      authSecret: process.env.REACT_APP_CONNECTYCUBE_AUTH_SECRET,
    };
    this.connectyCubeClient.init(CREDENTIALS);
  }

  client() {
    return this.connectyCubeClient;
  }

  async signup(userCredentials) {
    await this.connectyCubeClient.createSession();
    return this.connectyCubeClient.users.signup(userCredentials);
  }

  async logout() {
    if (this.connectyCubeClient.chat.isConnected) {
      await this.connectyCubeClient.chat.disconnect();
    }
    // await this.connectyCubeClient.destroySession();
  }

  async createSession(userCredentials) {
    try {
    const session = await this.connectyCubeClient.createSession(userCredentials);
    await this.connectyCubeClient.login(userCredentials);
    return session;      
  } catch (e) {
    console.log(e);
  }

  }

  isConnected() {
    return this.connectyCubeClient.chat.isConnected;
  }

  createChat(userCredentials) {
    if (!this.connectyCubeClient.chat.isConnected) {
      return this.connectyCubeClient.chat.connect(userCredentials);
    }
  }

  createDialog(opponentId) {
    const params = {
      type: 3,
      occupants_ids: [opponentId],
    };
    return this.connectyCubeClient.chat.dialog.create(params);
  }

  fetchDialog(filters = {}) {
    return this.connectyCubeClient.chat.dialog.list(filters);
  }

  sendMessage(dialogId, opponentId, body = 'How are you?') {
    const params = {
      type: 'chat',
      body,
      extension: {
        save_to_history: 1,
        dialog_id: dialogId,
      },
      markable: 1,
    };
    return this.connectyCubeClient.chat.send(opponentId, params);
  }

  receiveMessage(onMessage) {
    this.connectyCubeClient.chat.onMessageListener = onMessage;
  }

  async createCall(calleesIds = [], options = {}, extension = {}) {
    const sessionType = this.connectyCubeClient.videochat.CallType.VIDEO;
    const session = this.connectyCubeClient.videochat.createNewSession(calleesIds, sessionType, options);
    await session.getUserMedia(this.mediaParams);
    await session.call(extension, error => {});
  }

  async acceptCall(session, extension = {}) {
    await session.getUserMedia(this.mediaParams);
    session.accept(extension);
  }

  async acceptAndCreateCall(session) {
    await this.acceptCall(session);
    await this.createCall([session.initiatorID]);
  }

  async createVideoCall(calleesIds = [], feedbackCallback) {
    await this.createCall(calleesIds);
    this.connectyCubeClient.videochat.onCallListener = async (session, extension) => {
      feedbackCallback(session);
    };
    this.connectyCubeClient.videochat.onRemoteStreamListener = (session, userId, remoteStream) => {
      session.attachMediaStream("caller-video", remoteStream);
    };
  }

  async acceptVideoCall(confirmCallback) {
    this.connectyCubeClient.videochat.onCallListener = async (session, extension) => {
      confirmCallback(session);
    };
    this.connectyCubeClient.videochat.onRemoteStreamListener = (session, userId, remoteStream) => {
      session.attachMediaStream("caller-video", remoteStream);
    };
  }

  async addContactUser(userId, callback) {
    this.connectyCubeClient.chat.contactList.add(userId);
    this.connectyCubeClient.chat.onConfirmSubscribeListener = callback;
  }

  listenContactRequest(callback) {
    this.connectyCubeClient.chat.onSubscribeListener = callback;
  }

  confirmContactUser(userId) {
    this.connectyCubeClient.chat.contactList.confirm(userId);
  }

  async fetchContactUsers() {
    const contactList = await this.connectyCubeClient.chat.contactList.get();
    if (Object.keys(contactList).length === 0) {
      return {items: []};
    }
    const params = {
      page: 1,
      per_page: 300,
      filter: {
        field: "id",
        param: "in",
        value: Object.keys(contactList),
      },
    };
    return this.connectyCubeClient.users.get(params);
  }

  async fetchChatHistory(dialogId, skip = 0) {
    const params = {
      chat_dialog_id: dialogId,
      sort_desc: "date_sent",
      limit: 100,
      mark_as_read: 1,
      skip,
    };
    return this.connectyCubeClient.chat.message.list(params);
  }

  async updateAvatar(file) {
    const fileParams = {
      name: file.name,
      file: file,
      type: file.type,
      size: file.size,
      public: true,
    };
    const uploadedFile = await this.connectyCubeClient.storage.createAndUpload(fileParams);
    const updatedUserProfile = { avatar: uploadedFile.uid };
    return this.connectyCubeClient.users.update(updatedUserProfile);
  }

  getUserAvatar(avatar) {
    if (!avatar) {
      // const connectyCubeUserId = StorageService.getItem('connectyCubeUserId');
      // return StorageService.defaultAvatar(connectyCubeUserId);
      return userAvatar.default;
    }
    if (avatar.indexOf('https://') === 0) {
      return avatar;
    }
    try {
      return this.connectyCubeClient.storage.publicUrl(avatar);
    } catch (error) {
      console.log(avatar);
      return avatar;
    }
  }

  updateProfile(userCredentials) {
    return this.connectyCubeClient.users.update(userCredentials);
  }

  setMessageRead(messageId, userId, dialogId) {
    this.connectyCubeClient.chat.sendReadStatus({messageId, userId, dialogId});
  }

  async deleteUser() {
    await this.connectyCubeClient.users.delete()
  }
}

export default Object.freeze(new ConnectyCubeService());
