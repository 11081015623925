import React from 'react';
import Grid from '@material-ui/core/Grid';
import { hightLightColor } from '../../metadata';

export default class PrivacyPolicy extends React.Component {

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }

  render() {
    return <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px'}} className="flex-column">

          <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>Privacy Policy for English Detective</h3>
          <div style={{backgroundColor: '#f0f0f0', padding: '12px 24px 24px', borderRadius: '6px', marginBottom: '48px'}}>
            <Grid container spacing={0}>
              <Grid item xs={24} lg={24}>
                {/* <h3 style={{color: hightLightColor, fontSize: '28px', margin: '0px'}}>Welcome to English Detective</h3> */}
                <p style={{}}>A fully interactive text service available on iOS and Android phones. Our app has loads of information and useful functions to make your English learning experience easier and more enjoyable.</p>

              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              At English Detective, one of our main priorities is the privacy of our visitors. 
              This Privacy Policy document contains types of information that is collected and 
              recorded by English Detective and how we use it.
              </p>

              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              If you have additional questions or require more information about our Privacy 
              Policy, do not hesitate to contact us.
              </p>

              <p style={{fontWeight: 'bold', marginTop: '24px'}}>Log Files</p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              English Detective follows a standard procedure of using log files. These files 
              log visitors when they use app. The information collected by log files include 
              internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), 
              date and time stamp, referring/exit pages, and possibly the number of clicks. 
              These are not linked to any information that is personally identifiable. The 
              purpose of the information is for analyzing trends, administering the app, 
              tracking users' movement on the app, and gathering demographic information.
              </p>

              <p style={{fontWeight: 'bold', marginTop: '24px'}}>Our Advertising Partners</p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              Some advertisers in our app may use cookies and web beacons. Each of our advertising 
              partners has their own Privacy Policy for their policies on user data and 
              collecting information. 
              </p>

              <p style={{fontWeight: 'bold', marginTop: '24px'}}>Privacy Policies</p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              Third-party ad servers or ad networks uses technologies like cookies, JavaScript, 
              or Beacons that are used in their respective advertisements and links that appear 
              on English Detective. They automatically receive your IP address when this occurs. 
              These technologies are used to measure the effectiveness of their advertising 
              campaigns and/or to personalize the advertising content that you see on this 
              app or other apps or websites.
              </p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              Note that English Detective has no access to or control over these cookies that 
              are used by third-party advertisers.
              </p>

              <p style={{fontWeight: 'bold', marginTop: '24px'}}>Third Party Privacy Policies</p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              English Detective's Privacy Policy does not apply to other advertisers or websites. 
              Thus, we are advising you to consult the respective Privacy Policies of these 
              third-party ad servers for more detailed information. It may include their 
              practices and instructions about how to opt-out of certain options.
              </p>

              <p style={{fontWeight: 'bold', marginTop: '24px'}}>Children's Information</p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              Another part of our priority is adding protection for children while using the 
              internet. We encourage parents and guardians to observe, participate in, 
              and/or monitor and guide their online activity.
              </p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              English Detective does not knowingly collect any Personal Identifiable Information 
              from children under the age of 13. If you think that your child provided this kind 
              of information on our App, we strongly encourage you to contact us immediately and 
              we will do our best efforts to promptly remove such information from our records.
              </p>

              <p style={{fontWeight: 'bold', marginTop: '24px'}}>Online Privacy Policy Only</p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              This Privacy Policy applies only to our online activities and is valid for visitors 
              to our App with regards to the information that they shared and/or collect in English 
              Detective. This policy is not applicable to any information collected offline or via 
              channels other than this app. Our Privacy Policy was created with the help of the&nbsp;
              <a href="https://www.app-privacy-policy.com/app-privacy-policy-generator/" target="_blank" rel="noreferrer" style={{ color: 'blue' }}>App Privacy Policy Generator from App-Privacy-Policy.com</a>
              </p>

              <p style={{fontWeight: 'bold', marginTop: '24px'}}>Consent</p>
              <p style={{lineHeight: '24px', marginTop: '24px'}}>
              By using our app, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
              </p>



              </Grid>
            </Grid>
          </div>



        </div>
      </div>
  }
}