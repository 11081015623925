import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { mainColor  } from '../metadata';


export default class DialogElement extends React.Component {

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '-10%',
          ...this.props.dialogStyle,
        }}
      >
        <div style={{
          backgroundColor: '#ffffff',
          border: '1px solid #ffffff',
          borderRadius: '12px',
          boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
          padding: '12px 16px',
          outline: 0,
          ...this.props.style,
        }}>
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.props.onCancel && (
              <Button
                variant="outlined"
                color="primary"
                className="anglo-block-button"
                onClick={this.props.onCancel}
                disabled={this.props.disabled}
                style={{
                  width: '100%',
                  textTransform: 'none',
                  justifyContent: 'left',
                  padding: '5px 12px',
                  marginBottom: '12px'}}>
                {this.props.cancelText || 'Cancel'} 
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              className="anglo-block-button"
              onClick={this.props.onOk}
              disabled={this.props.disabled}
              style={{
                width: '100%',
                textTransform: 'none',
                justifyContent: 'left',
                padding: '5px 12px',
                marginBottom: '12px',
                backgroundColor: mainColor}}>
              {this.props.okText || 'Ok'} 
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}