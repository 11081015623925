import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { mainColor, whiteTextColor } from '../../metadata';

export default class DictionaryHeader extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: '18px' }}>
        <TextField
          label="Search"
          color="secondary"
          style={{
            width: this.props.bilingual || this.props.multiple ? '30%' : '50%'
          }}
          value={this.props.word}
          onChange={(event) => this.props.onWordChange(event.target.value)}
          onKeyDown={(event) => 'Enter' === event.key && this.props.onSearch()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <CloseOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={this.props.onClear}
                />
              </InputAdornment>
            )
          }}
        />
        <IconButton
          color="secondary"
          onClick={this.props.onSearch}
          style={{
            margin: '5px 24px 5px 0px',
            color: whiteTextColor,
            backgroundColor: mainColor,
            fontSize: '14px'
          }}
        >
          <SearchOutlined />
        </IconButton>
        {this.props.bilingual && (
          <TextField
            select
            label="Language"
            color="secondary"
            style={{ width: '30%', lineHeight: '35px' }}
            value={this.props.language}
            onChange={(event) =>
              this.props.onLanguageChange(event.target.value)
            }
          >
            <MenuItem value="ldoce5">English</MenuItem>
            {/*<MenuItem value="ldec">Chinese</MenuItem>*/}
          </TextField>
        )}
        {this.props.multiple && (
          <React.Fragment>
            <TextField
              select
              label="From"
              color="secondary"
              style={{ width: '15%', lineHeight: '35px' }}
              value={this.props.fromLanguage}
              onChange={(event) =>
                this.props.onFromLanguageChange(event.target.value)
              }
            >
              {this.props.languages.map((lang) => {
                return (
                  <MenuItem value={lang.code} key={`from-${lang.code}`}>
                    {lang.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <span style={{ padding: '0px 10px', lineHeight: '64px' }}>To</span>
            <TextField
              select
              label="To"
              color="secondary"
              style={{ width: '15%', lineHeight: '35px' }}
              value={this.props.toLanguage}
              onChange={(event) =>
                this.props.onToLanguageChange(event.target.value)
              }
            >
              {this.props.languages.map((lang) => {
                return (
                  <MenuItem value={lang.code} key={`to-${lang.code}`}>
                    {lang.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </React.Fragment>
        )}
      </div>
    );
  }
}
