import React from 'react';
import Grid from '@material-ui/core/Grid';
import { hightLightColor } from '../../metadata';

export default class Disclaimer extends React.Component {

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }

  render() {
    return <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', minHeight: '600px'}} className="flex-column">

          <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>Disclaimer for English Detective</h3>
          <div style={{backgroundColor: '#f0f0f0', padding: '12px 24px 24px', borderRadius: '6px', marginBottom: '48px'}}>
            <Grid container spacing={0}>
              <Grid item xs={24} lg={24}>




            <p style={{lineHeight: '24px', marginTop: '24px'}}>
                We are doing our best to prepare the content of this app. However, 
                English Detective cannot warranty the expressions and suggestions 
                of the contents, as well as its accuracy. In addition, to the extent 
                permitted by the law, English Detective shall not be responsible for 
                any losses and/or damages due to the usage of the information on our app. 
                Our Disclaimer was generated with the help of the &nbsp;

            <a href="https://www.app-privacy-policy.com/app-disclaimer-generator/" rel="noreferrer" target="_blank" style={{ color: 'blue' }}>App Disclaimer Generator from App-Privacy-Policy.com</a></p>

                <p style={{lineHeight: '24px', marginTop: '24px'}}>
                By using our app, you hereby consent to our disclaimer and agree to its terms.
                </p>
                <p style={{lineHeight: '24px', marginTop: '24px'}}>
                Any links contained in our app which may lead to external sites are 
                provided for convenience only. Any information or statements that 
                appeared in these sites or app are not sponsored, endorsed, or otherwise 
                approved by English Detective. For these external sites, English Detective 
                cannot be held liable for the availability of, or the content located on 
                or through it. Plus, any losses or damages occurred from using these 
                contents or the internet generally.
                </p>




              </Grid>
            </Grid>
          </div>



        </div>
      </div>
  }
}