import React from 'react';

import HttpService from '../../services/HttpService';


export default class GlobepayPayment extends React.Component {

  intervalIndex = 0;

  state = {
    paid: false,
    description: 'Scaning QR code to complete the payment',
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    clearInterval(this.intervalIndex);
  }

  initialize = () => {
    this.intervalIndex = setInterval(async () => {
      const verifyResult = await HttpService.verifyGlobepayPayment({paymentId: this.props.paymentId});
      if (verifyResult.success) {
        clearInterval(this.intervalIndex);
        this.setState({
          paid: true,
          description: 'Pay successfully! Redirecting...'
        });
        const timeoutIndex = setTimeout(() => {
          clearTimeout(timeoutIndex);
          this.props.history.push({pathname: '/user/2'});
        }, 2000);
      }
    }, 2000);
  };

  render() {
    return (
      <React.Fragment>
        <div style={{textAlign: 'center'}}>
          <h4>{this.state.description}</h4>
          {!this.state.paid && (
            <img src={this.props.qrcode} alt="qrcode" style={{width: '240px', height: '240px'}} />
          )}
        </div>
      </React.Fragment>
    );
  }
}
