import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import ConnectyCubeService from '../../services/ConnectyCubeService';
import StorageService from '../../services/StorageService';
import { mainColor } from '../../metadata';
import MessageElement from '../../components/MessageElement';
import HttpService from '../../services/HttpService';

export default class Security extends React.Component {

  password = StorageService.getItem('connectyCubePassword');
  userType = StorageService.getItem('userType');

  state = {
    oldPassword: 'wechat' === this.userType ? this.password : '',
    // oldPassword: '',
    newPassword1: '',
    newPassword2: '',
    saving: false,
    snackbarOpen: false,
    snackbarSeverity: 'error',
    snackbarMessage: '',
  };

  updatePassword = async () => {
    if (!this.state.oldPassword.trim()) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Old password is required!',
      });
      return;
    }
    if (this.state.oldPassword !== this.password) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Old password is not right!',
      });
      return;
    }
    if (!this.state.newPassword1.trim()) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'New password is required!',
      });
      return;
    }
    if (this.state.newPassword1 !== this.state.newPassword2) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'The two passwords are not the same!',
      });
      return;
    }
    if (this.state.newPassword1.length < 7) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Password lenght must be longer then 6!',
      });
      return;
    }
    this.setState({saving: true});
    try {
      await HttpService.updatePassword({
        password: this.state.newPassword1,
        oldPassword: this.state.oldPassword,
      });
      try {
        await ConnectyCubeService.updateProfile({
          login: StorageService.getItem('connectyCubeUsername'),
          old_password: this.state.oldPassword,
          password: this.state.newPassword1,
        });        
      } catch (error) {
        await HttpService.updatePassword({
          password: this.state.oldPassword,
          oldPassword: this.state.newPassword1,
        });
        throw error;
      }
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'success',
        snackbarMessage: 'Update password success!',
      });
      this.props.logoutAction();
    } catch (error) {
      // console.log(error);
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Update password failed!',
      });
    } finally {
      this.setState({saving: false});
    }
  };

  render() {
    return (
      <div style={{height: '100%', padding: '20px'}}>
        <div style={{marginBottom: '12px'}}>
          <TextField label="Old password" color="secondary" style={{width: '300px'}}
            value={this.state.oldPassword}
            type="password"
            disabled={'wechat' === this.userType}
            onChange={event => this.setState({oldPassword: event.target.value})}
          />
        </div>

        <div style={{marginBottom: '12px'}}>
          <TextField label="New password" color="secondary" style={{width: '300px'}}
            value={this.state.newPassword1}
            type="password"
            // disabled={'wechat' === this.userType}
            onChange={event => this.setState({newPassword1: event.target.value})}
          />
        </div>

        <div style={{marginBottom: '12px'}}>
          <TextField label="Repeat new password" color="secondary" style={{width: '300px'}}
            value={this.state.newPassword2}
            type="password"
            // disabled={'wechat' === this.userType}
            onChange={event => this.setState({newPassword2: event.target.value})}
          />
        </div>

        <div style={{marginTop: '36px'}}>
          <Button variant="contained" color="secondary"
            style={{width: '300px', textTransform: 'none', padding: '5px 12px', backgroundColor: mainColor, fontSize: '16'}}
            onClick={this.updatePassword}
            disabled={this.state.saving/* || 'wechat' === this.userType*/}
          >
            {this.state.saving && <CircularProgress size={16} style={{color: '#f0f0f0'}} />}
            <span style={{marginLeft: '6px', color: '#f0f0f0'}}>Update Password{this.state.saving && ' ...'}</span>
          </Button>
        </div>        
        <MessageElement
          open={this.state.snackbarOpen}
          onClose={() => this.setState({snackbarOpen: false})}
          severity={this.state.snackbarSeverity}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}