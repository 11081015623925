import React from 'react';


export default class TitleElement extends React.Component {

  render() {
    return (
      <React.Fragment>
        <h3 style={{
          fontSize: '24px',
          fontWeight: 'bold',
          margin: '0px 0px 12px',
          ...this.props.titleStyle,
        }}>
          {this.props.title}
        </h3>
        {this.props.description && (
          <p style={{
            margin: '0px 0px 12px',
            fontStyle: 'italic',
            ...this.props.descriptionStyle,
          }}>
            {this.props.description}
          </p>
        )}
      </React.Fragment>
    );
  }
}
