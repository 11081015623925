import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DictionaryHeader from './DictionaryHeader';
import DictionaryService from '../../services/DictionaryService';
import { mainColor } from '../../metadata';

export default function NormalDictionary(props) {
  const [searchWord, setsearchWord] = useState('');
  const [searchLoad, setsearchLoad] = useState(false);
  const [searchData, setsearchData] = useState([]);
  const [offset, setoffset] = useState(0);
  const [total, settotal] = useState(0);
  const [type, settype] = useState('');

  const services = {
    financialDictionary: 'searchFinancialWord',
    medicalDictionary: 'searchMedicalWord',
    legalDictionary: 'searchLegalWord',
    idiomsDictionary: 'searchIdiomsWord',
    thesaurusDictionary: 'searchThesaurusWord'
  };

  const clearSearch = () => {
    setsearchData([]);
    setsearchWord('');
    setsearchLoad(false);
    setoffset(0);
    settotal(0);
  };

  useEffect(() => {
    settype(props.type);
    clearSearch();
  }, [props]);

  const onSearchWord = async () => {
    if(searchWord) {
      setsearchLoad(true);
      const searchResult = await DictionaryService[services[type]]({
        search: searchWord,
        offset: 0
      });
      let result = [];
      if (type === 'thesaurusDictionary') {
        result = searchResult.response;
      } else {
        result = searchResult.results;
        settotal(searchResult.total);
      }
      setsearchData(result);
      setsearchLoad(false);
    }
  };

  const loadMore = async () => {
    setsearchLoad(true);
    const searchResult = await DictionaryService[services[type]]({
      search: searchWord,
      offset: (offset + 1) * 10
    });
    let result = [];
    if (type === 'thesaurusDictionary') {
      result = searchResult.response;
    } else {
      result = searchResult.results;
      settotal(searchResult.total);
    }
    setsearchData([...searchData, ...result]);
    setoffset(offset + 1);
    setsearchLoad(false);
  };

  const renderResults = (results) => {
    return (
      <div
        className="anglo-card"
        style={{
          backgroundColor: '#f0f0f0',
          marginBottom: '12px',
          padding: '12px',
          borderRadius: '6px'
        }}
      >
        {(!searchLoad && 0 === results.length) ? (
          <div style={{ padding: '20px 0px' }}>No results found!</div>
        ) : (
          results.map((data, index) => (
            <div
              key={type === 'thesaurusDictionary' ? index : data.id}
              style={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <div
                style={{
                  width: '22px',
                  height: '22px',
                  lineHeight: '22px',
                  borderRadius: '11px',
                  backgroundColor: mainColor,
                  textAlign: 'center',
                  color: '#f0f0f0',
                  fontSize: '13px',
                  display: 'inline-block'
                }}
              >
                {index + 1}
              </div>
              <span
                style={{
                  marginLeft: '6px',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                {type === 'thesaurusDictionary'
                  ? data.list.category
                  : data.phrase}
              </span>
              <div>
                <span className="anglo-first-letter-capitalize">
                  {type === 'thesaurusDictionary'
                    ? data.list.synonyms
                    : data.definition}
                </span>
              </div>
            </div>
          ))
        )}
        <div>
          {searchLoad && (
            <CircularProgress
              size={40}
              color="secondary"
              style={{ color: mainColor }}
            />
          )}
        </div>
        {type !== 'thesaurusDictionary' && total > (offset + 1) * 10 && (
          <div style={{ marginTop: '30px' }}>
            <Button
              variant="outlined"
              style={{ color: '#dc3545', borderColor: '#dc3545' }}
              onClick={loadMore}
            >
              See More
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <DictionaryHeader
        bilingual={false}
        word={searchWord}
        onWordChange={(value) => setsearchWord(value)}
        onClear={clearSearch}
        onSearch={() => {
          setsearchData([]);
          onSearchWord();
        }}
      />
      <div className="flex-1" style={{ overflow: 'auto' }}>
        {searchData && renderResults(searchData)}
      </div>
    </React.Fragment>
  );
}
