import React, { useEffect, useState, useRef, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {
  PlusCircleOutlined,
  BulbOutlined,
  RedoOutlined
} from '@ant-design/icons';
import DictionaryService from '../../services/DictionaryService';

const useStyles = makeStyles({
  textPrimary: {
    color: '#007bff',
    fontSize: 28
  },
  textDanger: {
    color: '#dc3545',
    fontSize: 28
  },
  textSuccess: {
    color: '#28a745',
    fontSize: 28
  },
  newWordBtn: {
    color: '#28a745',
    borderColor: '#28a745',
    marginRight: '15px'
  },
  hintWordBtn: {
    color: '#dc3545',
    borderColor: '#dc3545',
    marginRight: '15px'
  },
  cancelWordBtn: {
    color: '#007bff', 
    borderColor: '#007bff'
  }
});

export default function GameVowels() {
  const classes = useStyles();
  const VOWELS = useRef(['a', 'e', 'i', 'o', 'u']);
  const answerState = useMemo(() => ({ init: 0, right: 1, mistake: 2 }), []);
  const [category, setCategory] = useState('');
  const [word, setWord] = useState('');
  const [initialLetter, setInitialLetter] = useState([]);
  const [successLetter, setSuccessLetter] = useState([]);
  const [maskLetters, setMaskLetters] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const getWord = async () => {
    const result = await DictionaryService.getGameWord();
    if (result) {
      setCategory(result.category);
      setWord(result.word);
    }
  };

  useEffect(() => {
    getWord();
  }, []);

  useEffect(() => {
    const charArray = word.split('');
    let successArray = [];
    let maskLetterArray = [];
    charArray.forEach((char, index) => {
      let val = VOWELS.current.filter((vowel) => vowel === char);
      if (val[0] !== undefined && val[0] === char) {
        successArray.push({
          val: val[0],
          index: index,
          isRight: answerState.right
        });
        maskLetterArray.push({
          val: '_',
          index: index,
          isRight: answerState.mistake
        });
      } else {
        maskLetterArray.push({
          val: char,
          index: index,
          isRight: answerState.init
        });
      }
    });
    setSuccessLetter(successArray);
    setMaskLetters(maskLetterArray);
    setInitialLetter(maskLetterArray);
    setIsVisible(false);
  }, [word, VOWELS, answerState]);

  const renderWord = () => {
    if (maskLetters !== undefined && maskLetters.length !== 0) {
      return (
        <div>
          {maskLetters.map((letter) => {
            let textStyle = classes.textPrimary;
            if (letter.val === '_') {
              textStyle = classes.textDanger;
            } else if (letter.isRight === answerState.right) {
              textStyle = classes.textSuccess;
            } else if (letter.isRight === answerState.mistake) {
              textStyle = classes.textDanger;
            }
            return (
              <span key={letter.index} className={textStyle}>
                {letter.val}
              </span>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderVowels = () => {
    return (
      <div style={{ marginTop: '30px' }}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {VOWELS.current.map((vowel, index) => {
            return (
              <Button
                key={index}
                style={{ textTransform: 'lowercase' }}
                onClick={() => onAnswer(vowel)}
              >
                {vowel}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div style={{ marginTop: '30px' }}>
        <Button
          variant="outlined"
          className={classes.newWordBtn}
          onClick={() => getWord()}
        >
          <PlusCircleOutlined style={{ paddingRight: '5px' }} />
          New
        </Button>
        <Button
          variant="outlined"
          className={classes.hintWordBtn}
          onClick={() => hint()}
        >
          <BulbOutlined style={{ paddingRight: '5px' }} />
          Hint
        </Button>
        <Button
          variant="outlined"
          className={classes.cancelWordBtn}
          onClick={() => reset()}
        >
          <RedoOutlined style={{ paddingRight: '5px' }} />
          Reset
        </Button>
      </div>
    );
  };

  const onAnswer = (answer) => {
    let flat = 0;
    let maskLetterArray = [];
    maskLetters.forEach((letter) => {
      let answering = successLetter.filter(
        (answer) => answer.index === letter.index
      );
      if (letter.val === '_' && flat === 0) {
        flat++;
        let isRight = answerState.init;
        if (answering.length > 0 && answering[0].val === answer) {
          isRight = answerState.right;
        } else {
          isRight = answerState.mistake;
        }
        maskLetterArray.push({
          val: answer,
          index: letter.index,
          isRight: isRight
        });
      } else {
        maskLetterArray.push({
          val: letter.val,
          index: letter.index,
          isRight: letter.isRight
        });
      }
    });
    setMaskLetters(maskLetterArray);
    solved(maskLetterArray);
  };

  const solved = (maskLetterArray) => {
    let mistakeList = maskLetterArray.filter((letter) => letter.isRight > 1);
    if (mistakeList.length === 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const reset = () => {
    setMaskLetters(initialLetter);
    setIsVisible(false);
  };

  const hint = () => {
    let flat = 0;
    let maskLetterArray = [];
    maskLetters.forEach((letter) => {
      let answering = successLetter.filter(
        (answer) => answer.index === letter.index
      );
      if (letter.val === '_' && flat === 0 && answering.length > 0) {
        flat++;
        maskLetterArray.push({
          val: answering[0].val,
          index: letter.index,
          isRight: answerState.right
        });
      } else {
        maskLetterArray.push({
          val: letter.val,
          index: letter.index,
          isRight: letter.isRight
        });
      }
    });
    setMaskLetters(maskLetterArray);
    solved(maskLetterArray);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ padding: '36px 16px' }}>
        <Grid item xs={12} md={12}>
          <Paper
            className="flex-column"
            style={{
              padding: '10px',
              textAlign: 'center',
              minHeight: '30px'
            }}
          >
            <p style={{ fontSize: '16px' }}>
              Click or tap on a missing letter in the word. Then select the
              correct vowel to go in that space.
            </p>
          </Paper>
        </Grid>
      </Grid>
      <div
        className="anglo-card"
        style={{
          backgroundColor: '#f0f0f0',
          marginBottom: '12px',
          padding: '12px',
          borderRadius: '6px'
        }}
      >
        <h4 style={{ fontWeight: 'bold', margin: '12px 0px' }}>
          Word category: {category}
        </h4>
        <Divider />

        <div style={{ marginTop: '10px', padding: '15px' }}>
          {renderWord()}
          {renderVowels()}
          {renderButtons()}
          {isVisible && (
            <div
              style={{
                marginTop: '40px',
                fontWeight: 'bold',
                fontSize: '20px',
                color: '#a4c936'
              }}
            >
              <span style={{ paddingBottom: '10px' }}>
                Congratulations, you solved it!
              </span>
              {word.definition && <span>Definition: {word.definition} </span>}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
