class WechatService {

  wechatApi = 'https://open.weixin.qq.com';

  getWechatApi = () => {
    return this.wechatApi;
  }

  getWechatQrCodeApi = uuid => {
    let wechatQrCodeApi = this.wechatApi + '/connect/qrconnect';
    wechatQrCodeApi += `?appid=${process.env.REACT_APP_WECHAT_APPID}`;
    wechatQrCodeApi += `&redirect_uri=${process.env.REACT_APP_HOST}/wechat-login`;
    wechatQrCodeApi += `&response_type=code&scope=snsapi_login&state=${uuid}#wechat_redirect`;
    return wechatQrCodeApi;
  }
}

export default Object.freeze(new WechatService());
