import React from 'react';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Hidden from '@material-ui/core/Hidden';
import { LogoutOutlined, UserOutlined, WalletOutlined, LockOutlined, DeleteOutlined } from '@ant-design/icons';

import Auth from '../Auth/Auth';
import Billing from './Billing';
import Profile from './Profile';
import Security from './Security';
import TitleElement from '../../components/TitleElement';
import DialogElement from '../../components/DialogElement';
import StorageService from '../../services/StorageService';
import ConnectyCubeService from '../../services/ConnectyCubeService';
import { hightLightColor } from '../../metadata';


export default class User extends React.Component {

  state = {
    isLogin: false,
    tabKey: parseInt(this.props.match?.params?.tabKey || 0),
    logoutLoading: false,
    logoutDialogOpen: false,
    avatar: '',
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const isLogin = StorageService.getItem('connectyCubeToken') && StorageService.getItem('token');
    this.setState({ isLogin });
    if (isLogin) {
      if (!ConnectyCubeService.isConnected()) {
        await ConnectyCubeService.createSession({
          login: StorageService.getItem('connectyCubeUsername'),
          password: StorageService.getItem('connectyCubePassword'),
        });
      }
      this.setState({
        avatar: ConnectyCubeService.getUserAvatar(StorageService.getItem('avatar')),
      });
    }
  };

  logoutAction = async () => {
    this.setState({logoutLoading: true});
    await ConnectyCubeService.logout();
    StorageService.clear();
    this.setState({logoutDialogOpen: false});
    this.setState({logoutLoading: false});
    this.initialize();
  };

  render() {
    return (
      <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', height: '800px'}} className="flex-column">
          {this.state.isLogin ? (
            <React.Fragment>
              <div style={{display: 'flex', padding: '12px 26px'}}>
                <h3 style={{lineHeight: '40px', fontWeight: '600', margin: '0px 12px 0px 0px'}}>
                  {`Hi, ${StorageService.getItem('displayName') || StorageService.getItem('connectyCubeUsername')}`}
                </h3>
                <Avatar
                  alt="My Avatar"
                  // src={StorageService.getItem('avatar') || StorageService.defaultAvatar(StorageService.getItem('connectyCubeUserId'))}
                  src={this.state.avatar}
                  style={{marginLeft: '6px'}}
                />
                <div className="flex-1"></div>
                <Button
                  variant="text"
                  color="primary"
                  style={{
                    width: '120px',
                    textTransform: 'none',
                    padding: '5px 12px',
                    justifyContent: 'center',
                    marginLeft: '24px',
                    fontSize: '16px',
                  }}
                  onClick={event => this.setState({logoutDialogOpen: true})}
                >
                  <LogoutOutlined style={{marginRight: '6px'}} />
                  Logout
                </Button>
              </div>
              <div className="flex-1 flex" style={{height: '736px'}}>
                <Tabs
                  value={this.state.tabKey}
                  orientation="vertical"
                  indicatorColor="secondary"
                  onChange={(e, tabKey) => this.setState({tabKey})}
                  style={{borderRight: '1px solid #eeeeee'}}
                  className="anglo-tabs"
                >
                  <Tab style={{minWidth: '54px'}}
                    label={
                    <React.Fragment>
                      <UserOutlined style={{paddingLeft: '6px'}} />
                      <Hidden smDown>
                      <span style={{marginLeft: '6px', width: '188px', display: 'inline-block'}}>Profile</span>
                      </Hidden>
                    </React.Fragment>
                  } />
                  <Tab style={{minWidth: '54px'}}
                    label={
                    <React.Fragment>
                      <LockOutlined style={{paddingLeft: '6px'}} />
                      <Hidden smDown>
                      <span style={{marginLeft: '6px', width: '188px', display: 'inline-block'}}>Security</span>
                      </Hidden>
                    </React.Fragment>
                  } />
                  {!StorageService.isAdmin() && (
                    <Tab style={{minWidth: '54px'}}
                      label={
                      <React.Fragment>
                        <WalletOutlined style={{paddingLeft: '6px'}} />
                        <Hidden smDown>
                        <span style={{marginLeft: '6px', width: '188px', display: 'inline-block'}}>Billing</span>
                        </Hidden>
                      </React.Fragment>
                    } />
                  )}
                  {!StorageService.isAdmin() && (
                    <Tab style={{minWidth: '54px'}}
                      onClick={e => this.props.history.push("/delete-account?account=" + JSON.parse(localStorage.getItem("connectyCubeUsername")))}
                      label={
                      <React.Fragment>
                        <DeleteOutlined style={{paddingLeft: '6px'}} />
                        <Hidden smDown>
                        <span style={{marginLeft: '6px', width: '188px', display: 'inline-block'}}>Delete Account</span>
                        </Hidden>
                      </React.Fragment>
                    } />
                  )}
                </Tabs>
                <Paper className="flex-column flex-1">
                  {0 === this.state.tabKey && <Profile initialize={this.initialize} />}
                  {1 === this.state.tabKey && <Security logoutAction={this.logoutAction} />}
                  {2 === this.state.tabKey && <Billing />}
                </Paper>
              </div>
              <DialogElement
                title="Are you sure to logout?"
                description="This action will terminate all current chat sessions."
                disabled={this.state.logoutLoading}
                open={this.state.logoutDialogOpen}
                onOk={this.logoutAction}
                onCancel={() => this.setState({logoutDialogOpen: false})}
              />
            </React.Fragment>
          ) : (
            <div style={{margin: '0 auto'}}>
              <TitleElement
                title="Login English Detective"
                titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
                description="Welcome!"
                descriptionStyle={{margin: '24px 0px 36px', textAlign: 'center'}}
              />
              <Auth initialize={this.initialize} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
