class SettingStore {

  state = {
    pricingPlans: {}
  };

  setSettingType = Symbol('setSettingType');

  setSetting = settings => ({
    type: this.setSettingType,
    settings,
  });

  reducer = (state = this.state, action) => {
    switch (action.type) {
      case this.setSettingType:
        return {
          ...state,
          ...action.settings,
        };
      default:
        return state;
    }
  }
}

export default Object.freeze(new SettingStore());
