import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import TitleElement from '../../components/TitleElement';
import LoaderElement from '../../components/LoaderElement';
import { mainColor, hightLightColor } from '../../metadata';

import { connect } from 'react-redux';

class Pricing extends React.Component {

  choosePlan = plan => {
    this.props.history.push({
      pathname: '/payment',
      state: { plan },
    });
  };

  render() {
    return (
      <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', minHeight: '800px'}} className="flex-column">
          <TitleElement
            title="Pricing Plans"
            titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
            description="Choose your best plan that grows with you"
            descriptionStyle={{margin: '0px 0px 36px', textAlign: 'center'}}
          />
          <div className="flex-1">
            <Grid container spacing={2} style={{padding: '36px 16px'}}>
              <LoaderElement loading={Object.keys(this.props.setting.pricingPlans).length === 0}>
                {Object.keys(this.props.setting.pricingPlans).map(plan => (
                  <Grid item xs={12} md={4} key={plan}>
                    <Paper className="flex-column" style={{padding: '0px 24px', textAlign: 'center', height: '600px'}}>
                      <h4 style={{fontSize: '24px', fontWeight: 'bold', margin: '12px 0px'}}>
                        {this.props.setting.pricingPlans[plan].title}
                      </h4>
                      <Divider style={{backgroundColor: 'purple'}} />
                      <p style={{fontSize: '20px', fontWeight: 'bold', margin: '24px 0px'}}>
                        {this.props.setting.pricingPlans[plan].price} ￥
                      </p>
                      {this.props.setting.pricingPlans[plan].descriptions.map((text, index) => (
                        <p key={index} style={{fontSize: '15px', margin: '6px 0px'}}>
                          {text}
                        </p>
                      ))}
                      <div className="flex-1"></div>
                      <div style={{padding: '60px 0px', textAlign: 'center'}}>
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{width: '200px', textTransform: 'none', backgroundColor: mainColor}}
                          onClick={e => this.choosePlan(plan)}
                        >
                          Choose
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </LoaderElement>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    setting: state.setting,
  })
)(Pricing);
