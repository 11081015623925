export const mainColor = '#dc0000';
export const darkColor = '#ba1800';
export const hightLightColor = '#ff4040';

export const secondaryColor = 'white';
export const chatLimit = 100;

export const whiteTextColor = '#f0f0f0';
export const blackTextColor = '#202020';

