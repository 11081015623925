import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { mainColor  } from '../metadata';


export default class LoaderElement extends React.Component {

  render() {
    return this.props.loading ? (
      <div style={{textAlign: 'center', padding: '24px 0px', margin: '0 auto', ...this.props.style}}>
        <CircularProgress size={this.props.size || 40} color="secondary" style={{color: mainColor}} />
        <div style={{color: '#888888', fontStyle: 'italic', marginTop: '24px'}}>{this.props.loadingText || null}</div>
      </div>
    ) : (
      this.props.children || null
    );
  }
}
