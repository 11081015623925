import * as axios from 'axios';
import StorageService from './StorageService';
import ConnectyCubeService from './ConnectyCubeService';

class HttpService {

  apiHost = process.env.REACT_APP_API_HOST;

  async fetchSetting() {
    const responseData = await axios.get(`${this.apiHost}/authorizations/settings`);
    return responseData.data;
  }

  async register(params) {
    const responseData = await axios.post(`${this.apiHost}/authorizations/register`, params);
    return responseData.data;
  }

  async login(params) {
    const responseData = await axios.post(`${this.apiHost}/authorizations/login`, params);
    return responseData.data;
  }

  async wechatAuthorization(params) {
    const responseData = await axios.post(`${this.apiHost}/authorizations/wechat`, params);
    return responseData.data;
  }

  async deleteAccount(username, password) {
    const responseData = await axios.delete(`${this.apiHost}/authorizations/delete-account?username=${username}&password=${password}`);
    return responseData.data;
  }

  async get(uri) {
    try {
      const responseData = await axios.get(`${this.apiHost}${uri}`, {
        headers: { Authorization: StorageService.getItem('token') },
      });
      return responseData.data;
    } catch (error) {
      if (error.response && error.response.status === 406) {
        await ConnectyCubeService.logout();
        StorageService.clear();
        window.location.reload();
      }
    }
  }

  async post(uri, params) {
    try {
      const responseData = await axios.post(`${this.apiHost}${uri}`, params, {
        headers: { Authorization: StorageService.getItem('token') },
      });
      return responseData.data;
    } catch (error) {
      if (error.response && error.response.status === 406) {
        await ConnectyCubeService.logout();
        StorageService.clear();
        window.location.reload();
      }
    }
  }

  async put(uri, params) {
    try {
      const responseData = await axios.put(`${this.apiHost}${uri}`, params, {
        headers: { Authorization: StorageService.getItem('token') },
      });
      return responseData.data;
    } catch (error) {
      if (error.response && error.response.status === 406) {
        await ConnectyCubeService.logout();
        StorageService.clear();
        window.location.reload();
      }
    }
  }

  async delete(uri) {
    try {
      const responseData = await axios.delete(`${this.apiHost}${uri}`, {
        headers: { Authorization: StorageService.getItem('token') },
      });
      return responseData.data;
    } catch (error) {
      if (error.response && error.response.status === 406) {
        await ConnectyCubeService.logout();
        StorageService.clear();
        window.location.reload();
      }
    }
  }

  searchGeneralDictionary(searchWord, language) {
    return this.get(`/dictionaries/general?searchWord=${searchWord}&language=${language}`);
  }

  createStripePaymentSession(params) {
    return this.post('/payments/stripe/session', params);
  }

  completeStripePayment(params) {
    return this.post('/payments/stripe/completion', params);
  }

  fetchBilling(params) {
    return this.get('/payments/billings', params);
  }

  createWechatPaymentSession(params) {
    return this.post('/payments/jgoo/session', params);
  }
  
  verifyJgooPayment(params) {
    return this.get(`/payments/jgoo/verification/${params.paymentId}`);
  }

  createGlobepayPaymentSession(params) {
    return this.post('/payments/globepay/session', params);
  }

  verifyGlobepayPayment(params) {
    return this.get(`/payments/globepay/verification/${params.paymentId}`);
  }

  fetchMemberships(params) {
    return this.get(`/users/memberships?userIds=${params.userIds}`);
  }

  updatePassword(params) {
    return this.put('/users/password', params);
  }

  validateToken() {
    return this.get('/users/token/validation');
  }

  increaseQuery(params) {
    return this.put('/users/queries/increment', params);
  }

  fetchQueries() {
    return this.get('/users/queries');
  }

  fetchTopics(page = 1, size = 10) {
    return this.get(`/topics?page=${page}&size=${size}`);
  }

  createTopics(params) {
    return this.post('/topics', params);
  }

  updateTopics(params) {
    return this.put('/topics', params);
  }

  deleteTopics(topicId) {
    return this.delete('/topics?topicId=' + topicId);
  }

  fetchTopic(topicId) {
    return this.get('/topics/item?topicId=' + topicId);
  }
}

export default Object.freeze(new HttpService());
