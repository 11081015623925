import React from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EditOutlined } from '@ant-design/icons';

import ConnectyCubeService from '../../services/ConnectyCubeService';
import StorageService from '../../services/StorageService';
import { mainColor } from '../../metadata';
import MessageElement from '../../components/MessageElement';
import LoaderElement from '../../components/LoaderElement';

export default class Profile extends React.Component {

  state = {
    saving: false,
    showCover: false,
    showInput: false,
    displayName: StorageService.getItem('displayName') || '',
    userName: StorageService.getItem('connectyCubeUsername') || '',
    email: StorageService.getItem('connectyCubeEmail') || '',
    avatar: '',
    snackbarOpen: false,
    snackbarSeverity: 'error',
    snackbarMessage: '',
    loadingAvatar: true,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.setState({
      displayName: StorageService.getItem('displayName') || '',
      userName: StorageService.getItem('connectyCubeUsername') || '',
      email: StorageService.getItem('connectyCubeEmail') || '',
    });
    if (!ConnectyCubeService.isConnected()) {
      await ConnectyCubeService.createSession({
        login: StorageService.getItem('connectyCubeUsername'),
        password: StorageService.getItem('connectyCubePassword'),
      });
    }
    this.setState({loadingAvatar: true});
    await new Promise(resolve => setTimeout(() => resolve(true), 200));
    this.setState({
      avatar: ConnectyCubeService.getUserAvatar(StorageService.getItem('avatar')),
    });
    this.setState({loadingAvatar: false});
  };

  setShowInput = event => {
    this.setState({showInput: false}, () => {
      this.setState({showInput: true}, () => {
        document.getElementById('angloeta-avatar').click();
      });
    });
  };

  selectFile = async event => {
    this.setState({loadingAvatar: true});
    // this.setState({avatar: ''});
    try {
      const uploadedFile = await ConnectyCubeService.updateAvatar(event.target.files[0]);
      StorageService.setItem('avatar', uploadedFile.user.avatar);
      // await new Promise();
    } catch (error) {
      this.setState({loadingAvatar: false});
    } finally {
      // this.setState({loadingAvatar: false});
    }
    // this.initialize();
    this.setState({
      avatar: ConnectyCubeService.getUserAvatar(StorageService.getItem('avatar')),
    });
    await new Promise(resolve => setTimeout(() => resolve(true), 2000));
    this.setState({loadingAvatar: false});
    this.props.initialize();
  };

  updateProfile = async () => {
    if (!this.state.displayName) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Display name is required!',
      });
      return;
    }
    if (this.state.email.trim()) {
      const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg.test(this.state.email)) {
        this.setState({
          snackbarOpen: true,
          snackbarSeverity: 'error',
          snackbarMessage: 'Email format is incorrect!',
        });
        return;
      }
    }
    this.setState({saving: true});
    const userCredentials = {
      login: this.state.userName,
      full_name: this.state.displayName,
      email: this.state.email,
    };
    try {
      await ConnectyCubeService.updateProfile(userCredentials);
      StorageService.setItem('displayName', this.state.displayName);
      StorageService.setItem('connectyCubeEmail', this.state.email);
      this.props.initialize();
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'success',
        snackbarMessage: 'Update profile success!',
      });
    } catch (error) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Update profile failed!',
      });
    } finally {
      this.setState({saving: false});
    }
  }

  render() {
    return (
      <div style={{height: '100%', padding: '20px'}}>
        <div style={{width: '160px', height: '160px', margin: '36px 70px'}}>
          <LoaderElement loading={this.state.loadingAvatar}>
            <div style={{
              // width: '160px',
              // height: '160px',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${this.state.avatar})`,
              backgroundSize: 'cover',
              borderRadius: '80px',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
            }}
              onMouseEnter={e => this.setState({showCover: true})}
              onMouseLeave={e => this.setState({showCover: false})}
            >
              {this.state.showInput && (
                <input id="angloeta-avatar" type="file" style={{display: 'none'}}
                  onChange={this.selectFile}
                />
              )}
              {this.state.showCover && (
                <div style={{
                  width: '160px',
                  height: '160px',
                  borderRadius: '80px',
                  backgroundColor: 'rgb(20, 20, 20, 0.3)',
                  lineHeight: '160px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}>
                  <EditOutlined style={{color: 'white', fontSize: '32px'}}
                    onClick={this.setShowInput}
                  />
                </div>
              )}
            </div>
          </LoaderElement>
        </div>

        <div style={{marginBottom: '12px'}}>
          <TextField label="Username" color="secondary" style={{width: '300px'}}
            defaultValue={this.state.userName}
            disabled
          />
        </div>
        <div style={{marginBottom: '12px'}}>
          <TextField label="Full Name" color="secondary" style={{width: '300px'}}
            value={this.state.displayName}
            onChange={event => this.setState({displayName: event.target.value})}
          />
        </div>
        <div style={{marginBottom: '12px'}}>
          <TextField label="Email" color="secondary" style={{width: '300px'}}
            value={this.state.email}
            onChange={event => this.setState({email: event.target.value})}
          />
        </div>

        <div style={{marginTop: '36px'}}>
          <Button variant="contained" color="secondary"
            style={{width: '300px', textTransform: 'none', padding: '5px 12px', backgroundColor: mainColor, fontSize: '16'}}
            // onClick={event => this.props.login(this.state)}
            onClick={this.updateProfile}
            disabled={this.state.saving}
          >
            {this.state.saving && <CircularProgress size={16} style={{color: '#f0f0f0'}} />}
            <span style={{marginLeft: '6px', color: '#f0f0f0'}}>Save{this.state.saving && ' ...'}</span>
          </Button>
        </div>
        <MessageElement
          open={this.state.snackbarOpen}
          onClose={() => this.setState({snackbarOpen: false})}
          severity={this.state.snackbarSeverity}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}
