import React from 'react';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';

import LoaderElement from '../../components/LoaderElement';
import ConnectyCubeService from '../../services/ConnectyCubeService';
import StorageService from '../../services/StorageService';
import { mainColor, whiteTextColor } from '../../metadata';


export default class ChatBox extends React.Component {

  connectyCubeUserId = StorageService.getItem('connectyCubeUserId');
  isAdmin = StorageService.getItem('connectyCubeUserId') === StorageService.getItem('connectyCubeAdminId');

  isRequesting = false;
  appendedRows = true;
  state = {
    chatMessage: '',
    avatar: '',
    query: false,
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    document.getElementById('anglo-chatbox').removeEventListener('scroll', this.listenScroll);
  }

  initialize = async () => {
    this.setState({
      avatar: ConnectyCubeService.getUserAvatar(StorageService.getItem('avatar')),
    });
    document.getElementById('anglo-chatbox').addEventListener('scroll', this.listenScroll);
  };

  listenScroll = async event => {
    const angloChatboxDom = document.getElementById('anglo-chatbox');
    const scrollHeight = angloChatboxDom.scrollHeight;
    if (0 === angloChatboxDom.scrollTop && !this.isRequesting) {
      this.isRequesting = true;
      const appendedRows = await this.props.prependChatData(this.props.chatData.skip + this.props.chatData.limit);
      if (appendedRows > 0) {
        const currentScrollHeight = angloChatboxDom.scrollHeight;
        angloChatboxDom.scrollTo({top: currentScrollHeight - scrollHeight});
      }
      this.isRequesting = false;
    }
  };

  setMessage = e => {
    this.setState({chatMessage: e.target.value});
  };

  sendMessage = e => {
    if (!this.state.chatMessage.trim()) {
      return;
    }
    this.props.sendMessage(this.state.chatMessage, this.state.query);
    setTimeout(() => this.setState({chatMessage: ''}));
  };

  keyDownMessage = event => {
    if ('Enter' === event.key) {
      if (event.ctrlKey) {
        this.setState({
          chatMessage: `${this.state.chatMessage}\n`,
        });
      } else {
        this.sendMessage()
      }
    }
  };

  render() {
    // console.log(this.props);
    return (
      <Paper className="flex-1" style={{backgroundColor: '#f0f0f0'}}>
        <div id="anglo-chatbox" style={{height: '571px', overflowY: 'auto', padding: '16px 24px'}}>
          {this.props.chatReady ? (
            <ul style={{listStyle: 'none', margin: '0px', padding: '0px'}}>
              {this.props.chatData.items
                .map(item => ({
                  id: item._id,
                  created_at: item.created_at,
                  message: item.message,
                  sender_id: item.sender_id,
                  recipient_id: item.recipient_id
                }))
                .reverse()
                .map(data => {
                  if (data.sender_id === this.connectyCubeUserId) {
                    return (
                      <li key={data.id} style={{marginBottom: '24px', minHeight: '36px', display: 'flex'}}>
                        <div className="flex-1"></div>
                        <div style={{position: 'relative', width: '100px', padding: '0px 10px'}}>
                          <i style={{position: 'absolute', bottom: '4px', fontSize: '11px'}}>
                            {moment(data.created_at).format('YYYY-MM-DD HH:mm')}
                          </i>
                        </div>
                        <div style={{
                          minWidth: '400px',
                          maxWidth: '50%',
                          flex: '1',
                          padding: '12px 18px 16px',
                          borderRadius: '6px',
                          backgroundColor: '#95ec69',
                          color: '#202020',
                          lineHeight: '24px'
                        }}
                          dangerouslySetInnerHTML={{
                            __html: (data.message || '').replace(/\r\n/g, '<br />').replace(/\r/g, '<br />').replace(/\n/g, '<br />')
                          }}
                        >
                        </div>
                        <Avatar
                          alt="Contact user avatar"
                          src={this.state.avatar}
                          style={{marginLeft: '24px'}}
                        />
                      </li>
                    );
                  } else {
                    return (
                      <li key={data.id} style={{marginBottom: '24px', minHeight: '36px', display: 'flex'}}>
                        <Avatar
                          alt="Contact user avatar"
                          src={this.props.opponentAvatar}
                          style={{marginRight: '24px'}}
                        />
                        <div style={{
                          minWidth: '400px',
                          maxWidth: '50%',
                          flex: '1',
                          padding: '12px 18px 16px',
                          borderRadius: '6px',
                          backgroundColor: 'white',
                          lineHeight: '24px'
                        }}
                          dangerouslySetInnerHTML={{
                            __html: (data.message || '').replace(/\r\n/g, '<br />').replace(/\r/g, '<br />').replace(/\n/g, '<br />')
                          }}
                        >
                        </div>
                        <div style={{position: 'relative', width: '100px', padding: '0px 10px'}}>
                          <i style={{position: 'absolute', bottom: '4px', fontSize: '11px'}}>
                            {moment(data.created_at).format('YYYY-MM-DD HH:mm')}
                          </i>
                        </div>
                      </li>
                    );
                  }
                })
              }
            </ul>
          ) : (
            <LoaderElement loading={this.props.opponentId} loadingText="Loading data ..." />
          )}
        </div>
        <Divider />
        <div className="flex" style={{height: '36px'}}>

          {this.props.currentDialog && this.props.currentDialog.membership && (
            <div style={{padding: '2px 12px', lineHeight: '32px', fontSize: '13px'}} className="flex">
              <span>Queries:&nbsp;&nbsp;</span>
              <span>{this.props.currentDialog.usageQuery}/{this.props.currentDialog.countQuery}</span>
              {this.props.currentDialog.usageQuery >= this.props.currentDialog.countQuery && (
                <div style={{
                  margin: '0px 12px',
                  borderRadius: '16px',
                  backgroundColor: 'red',
                  width: '72px',
                  color: whiteTextColor,
                  textAlign: 'center'
                }}>Use Up!</div>
              )}
            </div>
          )}

        </div>
        <div className="flex" style={{height: '160px'}}>
          <div style={{width: '4px'}}></div>
          <div className="flex-1">
            <textarea
              style={{
                border: '0px',
                outline: '0px',
                width: '100%',
                height: '150px',
                resize: 'none',
                borderRadius: '3px',
                padding: '3px 6px',
              }}
              placeholder="Please input here..."
              value={this.state.chatMessage}
              onChange={this.setMessage}
              disabled={!this.props.opponentId || !this.props.chatReady}
              onKeyDown={this.keyDownMessage}
            ></textarea>
          </div>
          <div style={{width: '140px', padding: '0px 24px'}}>
            {this.props.opponentId && this.props.chatReady && (
              <React.Fragment>

                {this.isAdmin && this.props.currentDialog && this.props.currentDialog.membership && (
                  <div style={{margin: '-9px 0px 12px -9px'}}>
                    <Switch
                      checked={this.state.query}
                      onChange={e => this.setState({query: !this.state.query})}
                      name="checkedA"
                      className="angloeta-switch"
                    />
                    <span style={{fontSize: '13px'}}>Answer Query</span>
                  </div>
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  disabled={false}
                  style={{
                    width: '100%',
                    textTransform: 'none',
                    padding: '5px 12px',
                    fontSize: '16',
                    backgroundColor: mainColor,
                  }}
                  onClick={this.sendMessage}
                >
                  <span style={{marginLeft: '6px'}}>Send</span>
                </Button>

              </React.Fragment>
            )}
          </div>
        </div>
      </Paper>
    );
  }
}
