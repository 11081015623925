import React from 'react';

import TitleElement from '../../components/TitleElement';
import LoaderElement from '../../components/LoaderElement';
import HttpService from '../../services/HttpService';
import { hightLightColor } from '../../metadata';

export default class StripePayment extends React.Component {

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    if (this.props.match?.params?.paymentId) {
      await HttpService.completeStripePayment({
        paymentId: this.props.match.params.paymentId,
      });
      const timeoutIndex = setTimeout(() => {
        clearTimeout(timeoutIndex);
        this.props.history.push({pathname: '/user/2'});
      }, 2000);
    }
  };

  render() {
    return (
      <div className="anglo-container">
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', height: '800px'}} className="flex-column">
          {this.props.match?.params?.paymentId ? (
            <React.Fragment>
              <TitleElement
                title="Processing Payment ..."
                titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
              />
              <LoaderElement loading={true} />
            </React.Fragment>
          ) : (
            <TitleElement
              title="You don't have a payment!" 
              titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
            />
          )}
        </div>
      </div>
    );
  }
}
