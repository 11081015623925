import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


export default class MessageElement extends React.Component {

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center', padding: '0px' }}
        open={this.props.open}
        onClose={this.props.onClose}
        autoHideDuration={this.props.duration || 1000}
        className="anglo-message"
        style={{top: '80px'}}
        message={
          <Alert severity={this.props.severity || 'error'} variant="filled">
            {this.props.message || this.props.severity || 'Error!'}
          </Alert>
        }
      />
    );
  }
}
