import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mainColor } from '../../metadata';
import HttpService from '../../services/HttpService';
import MessageElement from '../../components/MessageElement';
import StorageService from '../../services/StorageService';
import ConnectyCubeService from '../../services/ConnectyCubeService';

export default class DeleteAccount extends React.Component {
  search = new URL(window.location).searchParams
  state = {
    username: this.search.get("account") || "",
    password: '',
    loading: false,
    snackbarOpen: false,
    snackbarSeverity: 'error',
    snackbarMessage: ""
  };

  deleteAccount = async () => {
    this.setState({ loading: true })
    if (!this.state.username.trim()) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Username is required to delete your account',
      });
      this.setState({ loading: false })
      return
    }
    if (!this.state.password.trim()) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Password is required to delete your account',
      });
      this.setState({ loading: false })
      return
    }
    try {
      await HttpService.deleteAccount(this.state.username.trim(), this.state.password.trim())
      await ConnectyCubeService.createSession({ login: this.state.username.trim(), password: this.state.password.trim() });
      await ConnectyCubeService.deleteUser()
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'success',
        snackbarMessage: 'Delete account successfully',
      });
      StorageService.clear();
      await new Promise(r => setTimeout(r, 1000))
      this.props.history.push({ pathname: "/" });
    } catch (error) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: error.response.data
      });
    } finally {
      this.setState({ loading: false })
    }
  }

  redirect = () => {
    this.props.history.push({ pathname: "/" });
  }

  render() {
    return (
      <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', margin: '0 auto', height: "600px"}}>
        <div style={{padding: '48px', textAlign: 'center'}}>
          <h2 style={{marginBottom: "24px"}}>Delete My Account</h2>
          <div style={{marginBottom: '12px', textAlign: 'center'}}>
            <TextField label="Username" color="secondary" style={{width: '300px'}}
              value={this.state.username}
              onChange={event => this.setState({username: event.target.value})}
            />
          </div>
          <div style={{marginBottom: '12px', textAlign: 'center'}}>
            <TextField label="Password" color="secondary" style={{width: '300px'}} type="password"
              value={this.state.password}
              onChange={event => this.setState({password: event.target.value})}
            />
          </div>
          <div style={{marginTop: '36px', textAlign: 'center', marginBottom: "26px"}}>
            <Button variant="contained"
              color="secondary"
              style={{width: '300px', textTransform: 'none', padding: '5px 12px', backgroundColor: mainColor, fontSize: '16'}}
              onClick={this.deleteAccount}
              disabled={this.state.loading}
            >
              {this.state.loading && <CircularProgress size={16} style={{color: '#f0f0f0'}} />}
              <span style={{marginLeft: '6px', color: '#f0f0f0'}}>Delete My Account</span>
            </Button>
          </div>
          <h3>
            Please note, you will lose all user data on the site after deleting your account!
          </h3>
          <div style={{color: "blue", cursor: "pointer"}} onClick={this.redirect}>I don't want to delete my account now!</div>
        </div>
        <MessageElement
          open={this.state.snackbarOpen}
          onClose={() => this.setState({snackbarOpen: false})}
          severity={this.state.snackbarSeverity}
          message={this.state.snackbarMessage}
        />

      </div>
    )
  }
}
