import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Carousel } from 'react-responsive-carousel';

import { mainColor, hightLightColor } from '../../metadata';
import * as c1 from '../../assets/1.jpg';
import * as c2 from '../../assets/2.jpg';
import * as c3 from '../../assets/3.jpg';
import * as c4 from '../../assets/4.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import * as cc1 from '../../assets/c1.png';
// import * as cc2 from '../../assets/c2.png';
// import * as cc3 from '../../assets/c3.png';
// import * as cc4 from '../../assets/c4.png';

import * as cc1 from '../../assets/xiaozhou.gif';
import * as cc2 from '../../assets/xiaowang.png';
import * as cc3 from '../../assets/minwang.gif';
import * as cc4 from '../../assets/cc5.png';

import odh from '../../assets/one-dollar-hotline.mp4';

export default class Home extends React.Component {

  render() {
    return (
      <React.Fragment>
        <div className="anglo-container" style={{marginTop: '48px'}}>
          <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>Welcome to English Detective</h3>
          <div style={{backgroundColor: '#f0f0f0', padding: '12px 24px 24px', borderRadius: '6px', marginBottom: '48px'}}>
            <Grid container spacing={0}>
              <Grid item xs={24} lg={24}>
                {/* <h3 style={{color: hightLightColor, fontSize: '28px', margin: '0px'}}>Welcome to English Detective</h3> */}
                <p style={{}}>A fully interactive text service available on iOS and Android phones. Our app has loads of information and useful functions to make your English learning experience easier and more enjoyable.</p>
              </Grid>
            </Grid>
          </div>

          <div style={{marginBottom: '48px'}}>
            <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>One Dollar Hotline</h3>
            <video width="100%" controls>
              <source src={odh} type="video/mp4" />
            </video>
          </div>

          <div style={{marginBottom: '48px'}}>
            <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>Our Specialities</h3>
            <div style={{backgroundColor: '#f0f0f0', padding: '12px 24px', borderRadius: '6px'}}>
            <p>Are you having a language breakdown or struggling with English grammar? Do not worry; the English Detective and his trusty friend Lexi are here to help you avoid any grammatical errors or embarrassing mistakes.
            </p>
            <p>Avoid silly errors at school, college, or work, to avoid losing marks in your exams or damaging your business reputation and image with bad English.
            </p>
            <p>The English Detective is waiting for your enquiry. He can help you with:</p>
            <ul>
              <li style={{fontWeight: 600, fontSize: '17px', marginBottom: '6px'}}>
                The correct choice and usage of a particular word, phrase, or figure of speech.
              </li>
              <li style={{fontWeight: 600, fontSize: '17px', marginBottom: '6px'}}>
                Checking syntax and sentence construction to eliminate hybrid English such as “Chinglish”.
              </li>
              <li style={{fontWeight: 600, fontSize: '17px', marginBottom: '6px'}}>
                Checking the quality of English on signs, messages, notices, brochures, and email, both for students and business subscribers.
              </li>
              <li style={{fontWeight: 600, fontSize: '17px', marginBottom: '6px'}}>
                Pointing out differences between UK and USA English writing.
              </li>
              <li style={{fontWeight: 600, fontSize: '17px', marginBottom: '6px'}}>
                Suggesting tips for IELTS students and how to address common problems.
              </li>
              <li style={{fontWeight: 600, fontSize: '17px', marginBottom: '6px'}}>
                Proof reading (see T & Cs).
              </li>
              <li style={{fontWeight: 600, fontSize: '17px', marginBottom: '6px'}}>
                Checking your English pronunciation.
              </li>
            </ul>
            <p>He will not be able to help you with understanding the complete works of Shakespeare, but he will help you with brief everyday language problems that people in school, college, office, factory, or the travel and hospitality industry encounter every day.
            </p>
            <p>If you are thinking about going to the UK for further study or travel and want to ask about cities and places to visit, just ask the English Detective; he knows all the best places for studying, working, and socialising. He can even update you on the weather in the UK - a national obsession!
            </p>
            </div>
          </div>

        </div>

        
        <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true}>
          <div style={{
            height: '640px',
            width: '100%',
            backgroundImage: `url(${c1.default})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            paddingTop: '160px'
          }}>
            <h2 style={{margin: '0px', fontSize: '36px', color: hightLightColor}}>The App</h2>
            <p style={{width: '400px', margin: '24px auto', backgroundColor: 'rgb(33,33,33,0.8)', borderRadius: '6px', padding: '12px 12px 16px', lineHeight: '24px', color: '#f0f0f0'}}>English Detective – the English language troubleshooter. Welcome to English Detective; a fully interactive text service available on iOS and Android phones. Our app has loads of information and useful functions to make your English learning experience easier and more enjoyable.</p>
          </div>
          <div style={{
            height: '640px',
            width: '100%',
            backgroundImage: `url(${c2.default})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            paddingTop: '160px'
          }}>
            <h2 style={{margin: '0px', fontSize: '36px', color: hightLightColor}}>Dictionary Corner</h2>
            <p style={{width: '400px', margin: '24px auto', backgroundColor: 'rgb(33,33,33,0.8)', borderRadius: '6px', padding: '12px 12px 16px', lineHeight: '24px', color: '#f0f0f0'}}>Includes the latest dictionaries for free use, including medical, financial, and legal specialities. Thesaurus for checking synonyms and increasing your vocabulary. A translation feature in 50 languages. A guide to idioms: the English language is full of them.</p>
          </div>
          <div style={{
            height: '640px',
            width: '100%',
            backgroundImage: `url(${c3.default})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            paddingTop: '160px'
          }}>
            <h2 style={{margin: '0px', fontSize: '36px', color: hightLightColor}}>Fun and Games</h2>
            <p style={{width: '400px', margin: '24px auto', backgroundColor: 'rgb(33,33,33,0.8)', borderRadius: '6px', padding: '12px 12px 16px', lineHeight: '24px', color: '#f0f0f0'}}>Be creative and try your hand at writing poetry. Submit it and the best poem will be published each month. The winner will receive free English Detective credits. Listen to a native English speaker narrating a popular novel with our range of classic audio books. Have fun and expand your vocabulary by playing a few word games.</p>
          </div>
          <div style={{
            height: '640px',
            width: '100%',
            backgroundImage: `url(${c4.default})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            paddingTop: '160px'
          }}>
            <h2 style={{margin: '0px', fontSize: '36px', color: hightLightColor}}>Chat by Text</h2>
            <p style={{width: '400px', margin: '24px auto', backgroundColor: 'rgb(33,33,33,0.8)', borderRadius: '6px', padding: '12px 12px 16px', lineHeight: '24px', color: '#f0f0f0'}}>Our interactive text service is available on subscription. There are some examples to inspire your questions on the home and chat pages.</p>
          </div>
        </Carousel>

        <div className="anglo-container" style={{marginTop: '48px'}}>
          <div style={{backgroundColor: '#f0f0f0', padding: '12px 24px 24px', borderRadius: '6px', marginBottom: '48px'}}>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={10}>
                <h3 style={{color: hightLightColor, fontSize: '28px', margin: '0px'}}>Download Apps</h3>
                <p style={{}}>Have you tried the English Detective app and chat service? Find it on iOS and Android</p>
              </Grid>
              <Grid item xs={12} lg={2} style={{textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Button variant="contained" color="secondary"
                  style={{width: '100px', textTransform: 'none', padding: '5px 12px', fontSize: '16', backgroundColor: mainColor, marginRight: '12px'}}
                  onClick={() => window.open("https://apps.apple.com/cn/app/english-detective/id1275445204")}
                >
                  <span style={{marginLeft: '6px'}}>iOS</span>
                </Button>
                <Button variant="contained" color="secondary"
                  style={{width: '100px', textTransform: 'none', padding: '5px 12px', fontSize: '16', backgroundColor: mainColor}}
                  onClick={() => window.open("https://play.google.com/store/apps/details?id=com.englishdetective.ltd")}
                >
                  <span style={{marginLeft: '6px'}}>Android</span>
                </Button>
              </Grid>
            </Grid>
          </div>

          <div style={{marginBottom: '48px'}}>
            <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>Audiobooks</h3>
            <div style={{backgroundColor: '#f0f0f0', padding: '12px 24px 24px', borderRadius: '6px', marginBottom: '48px'}}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <h4 style={{fontSize: '16px', margin: '0px 0px 12px'}}>The Adventures of Sherlock Holmes:</h4>
                  <div style={{marginBottom: '12px'}}>
                    <audio src={`http://www.englishtalkabout.com/static/audiobooks/adventuresherlockholmes.mp3`} controls></audio>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <h4 style={{fontSize: '16px', margin: '0px 0px 12px'}}>A Damsel in Distress:</h4>
                  <div style={{marginBottom: '12px'}}>
                    <audio src={`http://www.englishtalkabout.com/static/audiobooks/damsel_in_distress.mp3`} controls></audio>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <h4 style={{fontSize: '16px', margin: '0px 0px 12px'}}>Treasure Island:</h4>
                  <div style={{marginBottom: '12px'}}>
                    <audio src={`http://www.englishtalkabout.com/static/audiobooks/treasure_island.mp3`} controls></audio>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <h4 style={{fontSize: '16px', margin: '0px 0px 12px'}}>The Phantom of the Opera:</h4>
                  <div style={{marginBottom: '12px'}}>
                    <audio src={`http://www.englishtalkabout.com/static/audiobooks/phantomoftheopera.mp3`} controls></audio>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <h4 style={{fontSize: '16px', margin: '0px 0px 12px'}}>The Adventures of Pinocchio:</h4>
                  <div style={{marginBottom: '12px'}}>
                    <audio src={`http://www.englishtalkabout.com/static/audiobooks/pinocchio.mp3`} controls></audio>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{marginBottom: '48px'}}>
            <h3 style={{textAlign: 'center', color: hightLightColor, fontSize: '28px'}}>Interactive Text Service Examples</h3>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <h4 style={{fontSize: '18px', margin: '0px 0px 12px'}}>Xiao Zhou from Hefei asks:</h4>
                <div style={{
                  backgroundColor: '#f0f0f0',
                  padding: '12px 24px',
                  borderRadius: '6px',
                  height: '264px',
                  backgroundImage: `url(${cc1.default})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}></div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h4 style={{fontSize: '18px', margin: '0px 0px 12px'}}>Office worker Xiao Wang from Kunming:</h4>
                <div style={{
                  backgroundColor: '#f0f0f0',
                  padding: '12px 24px',
                  borderRadius: '6px',
                  height: '264px',
                  backgroundImage: `url(${cc2.default})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}></div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h4 style={{fontSize: '18px', margin: '0px 0px 12px'}}>Min Wang from Wuhan asks:</h4>
                <div style={{
                  backgroundColor: '#f0f0f0',
                  padding: '12px 24px',
                  borderRadius: '6px',
                  height: '264px',
                  backgroundImage: `url(${cc3.default})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}></div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h4 style={{fontSize: '18px', margin: '0px 0px 12px'}}>Student Mia from Tianjin asks:</h4>
                <div style={{
                  backgroundColor: '#f0f0f0',
                  padding: '12px 24px',
                  borderRadius: '6px',
                  height: '264px',
                  backgroundImage: `url(${cc4.default})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}></div>
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

