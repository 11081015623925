import React from 'react';
import { Switch, Route, Link } from "react-router-dom";
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {
  HomeOutlined,
  ReadOutlined,
  CommentOutlined,
  FormOutlined,
  UserOutlined,
  BarsOutlined,
} from '@ant-design/icons';

import ComputeElement from './components/ComputeElement';
import TitleElement from './components/TitleElement';
import Home from './routes/Home/Home';
import Detective from './routes/Detective/Detective';
import Chat from './routes/Chat/Chat';
import Blog from './routes/Blog/Blog';
import Topic from './routes/Blog/Topic';
import Pricing from './routes/Pricing/Pricing';
import Payment from './routes/Pricing/Payment';
import StripePayment from './routes/Pricing/StripePayment';
import HttpService from './services/HttpService';
import User from './routes/User/User';
import WechatLogin from './routes/Auth/WechatLogin';
import DeleteAccount from './routes/Auth/DeleteAccount';

import { mainColor, darkColor, hightLightColor, secondaryColor, whiteTextColor, blackTextColor } from './metadata';
import * as header from './assets/header.png';
import * as footer from './assets/footer.png';

import { connect } from 'react-redux';
import SettingStore from './stores/SettingStore';

import PrivacyPolicy from './routes/Policy/PrivacyPolicy';
import Eula from './routes/Policy/Eula';
import TermsConditions from './routes/Policy/TermsConditions';
import Disclaimer from './routes/Policy/Disclaimer';

const url1 = 'https://www.cambridgeenglish.org/learning-english';
const url2 = 'https://learnenglish.britishcouncil.org/';
const url3 = 'https://www.ielts.org/about-ielts/what-is-ielts';

class App extends React.Component {

  state = {
    anchorElement: null,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const settings = await HttpService.fetchSetting();
    this.props.setSetting(settings);
  }

  redirect = pathname => {
    this.setState({anchorElement: null});
    this.props.history.push({ pathname });
  };

  render() {
    return (
      <div className="flex-column" style={{backgroundColor: '#fcfcfc'}}>
        <div style={{backgroundColor: mainColor, position: 'fixed', width: '100%', zIndex: 99}}>
          <div className="anglo-container flex">
            <div style={{padding: '20px 0px 24px', height: '34px', lineHeight: '34px', width: '500px', maxWidth: '80%'}}>
            <img src={header.default} alt="header" style={{alignItems: 'left', width: '100%', padding: '0px 6px'}} />
            </div>
            <div className="flex-1"></div>
            <Hidden lgUp>
              <div style={{padding: '20px 12px 24px', height: '34px', lineHeight: '34px'}}>
                <BarsOutlined
                  style={{color: secondaryColor, fontSize: '28px', outline: 0}}
                  onClick={event => this.setState({anchorElement: event.currentTarget})}
                />
              </div>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorElement}
                keepMounted
                open={Boolean(this.state.anchorElement)}
                onClose={() => this.setState({anchorElement: null})}
                style={{top: '62px', right: '0px'}}
              >
                <ComputeElement
                  compute={['/', '/home'].includes(this.props.location.pathname)}
                  render={selected => (
                    <MenuItem
                      style={{
                        backgroundColor: selected ? mainColor : whiteTextColor,
                        color: selected ? secondaryColor : blackTextColor,
                      }}
                      onClick={() => this.redirect('/home')}
                    >
                      <HomeOutlined style={{color: selected ? secondaryColor : mainColor, marginRight: '6px'}} />
                      <span className="angloeta-text-font">Home</span>
                    </MenuItem>
                  )}
                />
                <ComputeElement
                  compute={this.props.location.pathname === '/detective'}
                  render={selected => (
                    <MenuItem
                      style={{
                        backgroundColor: selected ? mainColor : whiteTextColor,
                        color: selected ? secondaryColor : blackTextColor,
                      }}
                      onClick={() => this.redirect('/detective')}
                    >
                      <ReadOutlined style={{color: selected ? secondaryColor : mainColor, marginRight: '6px'}} />
                      <span className="angloeta-text-font">Detective</span>
                    </MenuItem>
                  )}
                />
                <ComputeElement
                  compute={this.props.location.pathname === '/chat'}
                  render={selected => (
                    <MenuItem
                      style={{
                        backgroundColor: selected ? mainColor : whiteTextColor,
                        color: selected ? secondaryColor : blackTextColor,
                      }}
                      onClick={() => this.redirect('/chat')}
                    >
                      <CommentOutlined style={{color: selected ? secondaryColor : mainColor, marginRight: '6px'}} />
                      <span className="angloeta-text-font">Chat</span>
                    </MenuItem>
                  )}
                />
                <ComputeElement
                  compute={this.props.location.pathname === '/blog'}
                  render={selected => (
                    <MenuItem
                      style={{
                        backgroundColor: selected ? mainColor : whiteTextColor,
                        color: selected ? secondaryColor : blackTextColor,
                      }}
                      onClick={() => this.redirect('/blog')}
                    >
                      <FormOutlined style={{color: selected ? secondaryColor : mainColor, marginRight: '6px'}} />
                      <span className="angloeta-text-font">Blog</span>
                    </MenuItem>
                  )}
                />
                <ComputeElement
                  compute={['/pricing', '/payment'].includes(this.props.location.pathname)}
                  render={selected => (
                    <MenuItem
                      style={{
                        backgroundColor: selected ? mainColor : whiteTextColor,
                        color: selected ? secondaryColor : blackTextColor,
                      }}
                      onClick={() => this.redirect('/pricing')}
                    >
                      <UserOutlined style={{color: selected ? secondaryColor : mainColor, marginRight: '6px'}} />
                      <span className="angloeta-text-font">Pricing</span>
                    </MenuItem>
                  )}
                />
                <ComputeElement
                  compute={this.props.location.pathname.split('/')[1] === 'user'}
                  render={selected => (
                    <MenuItem
                      style={{
                        backgroundColor: selected ? mainColor : whiteTextColor,
                        color: selected ? secondaryColor : blackTextColor,
                      }}
                      onClick={() => this.redirect('/user')}
                    >
                      <UserOutlined style={{color: selected ? secondaryColor : mainColor, marginRight: '6px'}} />
                      <span className="angloeta-text-font">User</span>
                    </MenuItem>
                  )}
                />
              </Menu>
            </Hidden>
            <Hidden mdDown>
              <ul style={{listStyle: 'none', margin: '0px', padding: '0px', display: 'flex', lineHeight: '74px', marginLeft: '48px', alignItems: 'right'}}>
                <li className="angloeta-menu angloeta-text-font"
                  style={{backgroundColor: ['/', '/home'].includes(this.props.location.pathname) ? hightLightColor : mainColor}}
                  onClick={event => this.props.history.push({pathname: '/home'})}>
                  <HomeOutlined style={{color: secondaryColor, marginRight: '6px'}} />
                  <span>Home</span>
                </li>
                <li className="angloeta-menu angloeta-text-font"
                  style={{backgroundColor: this.props.location.pathname === '/detective' ? hightLightColor : mainColor}}
                  onClick={event => this.props.history.push({pathname: '/detective'})}
                >
                  <ReadOutlined style={{color: secondaryColor, marginRight: '6px'}} />
                  <span>Detective</span>
                </li>
                <li className="angloeta-menu angloeta-text-font"
                  style={{backgroundColor: this.props.location.pathname === '/chat' ? hightLightColor : mainColor}}
                  onClick={event => this.props.history.push({pathname: '/chat'})}>
                  <CommentOutlined style={{color: secondaryColor, marginRight: '6px'}} />
                  <span>Chat</span>
                </li>
                <li className="angloeta-menu angloeta-text-font"
                  style={{backgroundColor: ['/blog', '/topic'].includes(this.props.location.pathname) ? hightLightColor : mainColor}}
                  onClick={event => this.props.history.push({pathname: '/blog'})}>
                  <FormOutlined style={{color: secondaryColor, marginRight: '6px'}} />
                  <span>Blog</span>
                </li>
                <li className="angloeta-menu angloeta-text-font"
                  style={{backgroundColor: ['/pricing', '/payment'].includes(this.props.location.pathname) ? hightLightColor : mainColor}}
                  // onClick={event => this.props.history.push({pathname: '/pricing'})}
                  onClick={event => this.redirect('pricing')}
                >
                  <UserOutlined style={{color: secondaryColor, marginRight: '6px'}} />
                  <span>Pricing</span>
                </li>
                <li className="angloeta-menu angloeta-text-font"
                  style={{backgroundColor: this.props.location.pathname.split('/')[1] === 'user' ? hightLightColor : mainColor}}
                  onClick={event => this.props.history.push({pathname: '/user'})}>
                  <UserOutlined style={{color: secondaryColor, marginRight: '6px'}} />
                  <span>User</span>
                </li>
              </ul>
            </Hidden>
          </div>
        </div>
        <div style={{paddingTop: '74px'}}>
          <Switch>
            <Route exact path="/home" component={ Home }></Route>
            <Route exact path="/detective" component={ Detective }></Route>
            <Route exact path="/chat" component={ Chat }></Route>
            <Route exact path="/blog" component={ Blog }></Route>
            <Route exact path="/topic" component={ Topic }></Route>
            <Route exact path="/pricing" component={ Pricing }></Route>
            <Route exact path="/payment" component={ Payment }></Route>
            <Route exact path="/payment/stripe/:paymentId" component={ StripePayment }></Route>
            <Route exact path="/user" component={ User }></Route>
            <Route exact path="/user/:tabKey" component={ User }></Route>
            <Route exact path="/wechat-login" component={ WechatLogin }></Route>
            <Route exact path="/privacy-policy" component={ PrivacyPolicy }></Route>
            <Route exact path="/eula" component={ Eula }></Route>
            <Route exact path="/terms-conditions" component={ TermsConditions }></Route>
            <Route exact path="/disclaimer" component={ Disclaimer }></Route>
            <Route exact path="/delete-account" component={ DeleteAccount }></Route>
            <Route path="**" component={ Home }></Route>
          </Switch>
        </div>
        <div style={{textAlign: 'center', backgroundImage: `url(${footer.default})`, height: '140px'}}></div>
        <div style={{backgroundColor: mainColor, padding: '0px 24px'}}>
          <div className="anglo-container flex" style={{padding: '16px 0px 12px'}}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={6}>
                <TitleElement
                  title="Welcome to English Detective"
                  titleStyle={{color: whiteTextColor}}
                  description="A fully interactive text service available on iOS and Android phones. Our app has loads of information and useful functions to make your English learning experience easier and more enjoyable."
                  descriptionStyle={{color: whiteTextColor}}
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TitleElement
                  title="Waiting for Your Enquiry"
                  titleStyle={{color: whiteTextColor}}
                  description="The English Detective is waiting for your enquiry, so send him a question. Log in, subscribe, and meet the English Detective, today."
                  descriptionStyle={{color: whiteTextColor}}
                />
                <span style={{color: 'white'}}>Contact us: </span>
                <a href="mailto:hello@englishtalkabout.com" style={{color: 'white', fontWeight: 'bold', marginLeft: '12px'}}><i>hello@englishtalkabout.com</i></a>
              </Grid>
              <Grid item xs={12} md={6}>
                <TitleElement
                  title="Links to learning"
                  titleStyle={{color: whiteTextColor}}
                  // description="The English Detective is waiting for your enquiry, so send him a question. Log in, subscribe, and meet the English Detective, today."
                  // descriptionStyle={{color: whiteTextColor}}
                />
                <div style={{ color: '#fff' }}>
                  Cambridge English: &nbsp;
                  <a href={url1} target="_blank" rel="noreferrer" style={{ color: '#fff' }}>{url1}</a>
                </div>
                <div style={{ color: '#fff', marginTop: '5px' }}>
                  British Council:  &nbsp;
                  <a href={url2} target="_blank" rel="noreferrer" style={{ color: '#fff' }}>{url2}</a>
                </div>
                <div style={{ color: '#fff', marginTop: '5px' }}>
                  IELTS:  &nbsp;
                  <a href={url3} target="_blank" rel="noreferrer" style={{ color: '#fff' }}>{url3}</a>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div style={{backgroundColor: darkColor, padding: '0px 24px'}}>
          <div className="anglo-container flex" style={{lineHeight: '36px', color: '#fcfcfc', fontSize: '12px'}}>
            &copy;Copyright 2021 englishtalkabout.com All Rights Reserved
            <Link to="/terms-conditions" style={{color: 'white', fontWeight: 'bold', marginLeft: '12px'}}><i>Terms & Conditions</i></Link>
            <Link to="/privacy-policy" style={{color: 'white', fontWeight: 'bold', marginLeft: '12px'}}><i>Privacy Policy</i></Link>
            <Link to="/disclaimer" style={{color: 'white', fontWeight: 'bold', marginLeft: '12px'}}><i>Disclaimer</i></Link>
            <Link to="/eula" style={{color: 'white', fontWeight: 'bold', marginLeft: '12px'}}><i>EULA</i></Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    setting: state.setting,
  }),
  dispatch => ({
    setSetting: setting => dispatch(SettingStore.setSetting(setting)),
  })
)(App);
