import React from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import ChatBox from './ChatBox';
import Contact from './Contact';
import Auth from '../Auth/Auth';
import TitleElement from '../../components/TitleElement';
import LoaderElement from '../../components/LoaderElement';
import StorageService from '../../services/StorageService';
import ConnectyCubeService from '../../services/ConnectyCubeService';
import HttpService from '../../services/HttpService';
import { hightLightColor } from '../../metadata';


export default class Chat extends React.Component {

  dialogId = null;
  connectyCubeAdminId = StorageService.getItem('connectyCubeAdminId');
  isAdmin = StorageService.getItem('connectyCubeUserId') === StorageService.getItem('connectyCubeAdminId');

  state = {
    isLogin: false,
    chatReady: false,
    chatData: {items: []},
    dialogReady: false,
    dialogData: {items: []},
    opponentId: null,
    opponentAvatar: '',
    userDialog: {},
  };

  componentDidMount() {
    this.initialize();
    this.initializeUserDialog();
  }

  componentWillUnmount() {

  }

  initialize = async () => {
    const isLogin = StorageService.getItem('connectyCubeToken') && StorageService.getItem('token');
    this.setState({isLogin});
    if (isLogin) {
      await HttpService.validateToken();
      // 确保创建了授权会话和聊天会话
      if (!ConnectyCubeService.isConnected()) {
        await ConnectyCubeService.createSession({
          login: StorageService.getItem('connectyCubeUsername'),
          password: StorageService.getItem('connectyCubePassword'),
        });
        await ConnectyCubeService.createChat({
          userId: StorageService.getItem('connectyCubeUserId'),
          password: StorageService.getItem('connectyCubePassword'),
        });
      }
      // 获得用户类型, 聊天对象, 监听消息
      this.listenDialog();
      if (this.isAdmin) {
        await this.fetchDialog();
      } else {
        await this.createDialog(this.connectyCubeAdminId);
      }
    }
  };

  initializeUserDialog = async () => {
    if (!this.isAdmin) {
      const queries = await HttpService.fetchQueries();
      this.setState({
        userDialog: queries.data
      });
    }
  };

  listenDialog = () => {
    ConnectyCubeService.receiveMessage(async (connectyCubeUserId, message) => {
      if (connectyCubeUserId === this.state.opponentId) {
        this.appendMessage(this.state.opponentId, StorageService.getItem('connectyCubeUserId'), message.body);
        ConnectyCubeService.setMessageRead(message.id, this.dialogId, StorageService.getItem('connectyCubeUserId'));
        await this.initializeUserDialog();
      } else {
        if (this.state.dialogData && this.state.dialogData.items && this.state.dialogData.items.length > 0) {
          const dialogIndex = this.state.dialogData.items.findIndex(dialog => dialog.user_id === connectyCubeUserId);
          if (-1 === dialogIndex) {
            await this.fetchDialog();
          } else {
            const targetDialog = this.state.dialogData.items[dialogIndex];
            targetDialog.unread_messages_count += 1;
            this.setState({
              dialogData: {
                ...this.state.dialogData,
                items: [
                  targetDialog,
                  ...this.state.dialogData.items.slice(0, dialogIndex),
                  ...this.state.dialogData.items.slice(1 + dialogIndex),
                ],
              }
            });
          }
        }
      }
    });
  };

  fetchDialog = async () => {
    const dialogData = await ConnectyCubeService.fetchDialog();
    this.setState({
      dialogReady: true,
      dialogData,
      chatData: {items: []},
    });
    for (let item of dialogData.items) {
      item.avatar = ConnectyCubeService.getUserAvatar(item.photo);
    }
    const userIds = dialogData.items.map(item => item.user_id).join(',');
    const memberships = await HttpService.fetchMemberships({userIds});
    for (let item of dialogData.items) {
      item.membership = memberships.data[item.user_id].membership;
      item.usageQuery = memberships.data[item.user_id].usageQuery;
      item.countQuery = memberships.data[item.user_id].countQuery;
      item.paymentId = memberships.data[item.user_id].paymentId;
    }
    this.setState({ dialogData });
  };

  createDialog = async opponentId => {
    this.setState({
      chatReady: false,
      opponentId,
    });
    const dialog = await ConnectyCubeService.createDialog(opponentId);
    this.dialogId = dialog._id;
    const chatData = await ConnectyCubeService.fetchChatHistory(this.dialogId);
    this.setState({
      chatReady: true,
      chatData,
      opponentAvatar: ConnectyCubeService.getUserAvatar(dialog.photo),
    });
    this.scrollToChetboxBottom();
  };

  prependChatData = async skip => {
    const chatData = await ConnectyCubeService.fetchChatHistory(this.dialogId, skip);
    this.setState({
      chatData: {
        ...chatData,
        items: [
          ...this.state.chatData.items,
          ...chatData.items,
        ]
      },
    });
    return chatData.items.length;
  };

  selectDialog = async dialog => {
    const dialogIndex = this.state.dialogData.items.findIndex(item => item._id === dialog._id);
    const targetDialog = this.state.dialogData.items[dialogIndex];
    targetDialog.unread_messages_count = 0;
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        items: [
          ...this.state.dialogData.items.slice(0, dialogIndex),
          targetDialog,
          ...this.state.dialogData.items.slice(1 + dialogIndex),
        ],
      },
    });
    await this.createDialog(dialog.user_id);
  };

  sendMessage = async (message, addQuery = false) => {
    this.appendMessage(StorageService.getItem('connectyCubeUserId'), this.state.opponentId, message);
    await ConnectyCubeService.sendMessage(this.dialogId, this.state.opponentId, message);
    if (this.isAdmin && addQuery) {
      const dialogIndex = this.state.dialogData.items.findIndex(item => item._id === this.dialogId);
      const targetDialog = this.state.dialogData.items[dialogIndex];
      await HttpService.increaseQuery({paymentId: targetDialog.paymentId});
      ++ targetDialog.usageQuery;
      this.setState({
        dialogData: {
          ...this.state.dialogData,
          items: [
            ...this.state.dialogData.items.slice(0, dialogIndex),
            targetDialog,
            ...this.state.dialogData.items.slice(1 + dialogIndex),
          ],
        },
      });
    }
  };

  appendMessage = (senderId, recipientId, message) => {
    const append = {
      _id: uuid(),
      created_at: moment().utc().format(),
      message: message,
      sender_id: senderId,
      recipient_id: recipientId,
    };
    this.setState({
      chatData: {
        ...this.state.chatData,
        items: [
          append,
          ...this.state.chatData.items,
        ],
      }
    });
    this.scrollToChetboxBottom();
  };

  scrollToChetboxBottom = () => {
    const timeoutIndex = setTimeout(() => {
      const angloChatboxDom = document.getElementById('anglo-chatbox');
      if (angloChatboxDom) {
        angloChatboxDom.scrollTo({top: angloChatboxDom.scrollHeight, behavior: "smooth"});
      }
      clearTimeout(timeoutIndex);
    });
  };

  render() {
    return (
      <div className="anglo-container" style={{marginTop: '24px'}}>
        <div style={{backgroundColor: '#f0f0f0', borderRadius: '6px', height: '800px'}} className="flex">
          {this.state.isLogin ? (
            <LoaderElement loading={!this.state.dialogReady && !this.state.chatReady}>
              {this.state.dialogReady && (
                <Contact
                  dialogReady={this.state.dialogReady}
                  dialogData={this.state.dialogData}
                  opponentId={this.state.opponentId}
                  selectDialog={this.selectDialog}
                />
              )}
              <ChatBox
                dialogReady={this.state.dialogReady}
                chatReady={this.state.chatReady}
                opponentId={this.state.opponentId}
                opponentAvatar={this.state.opponentAvatar}
                chatData={this.state.chatData}
                sendMessage={this.sendMessage}
                prependChatData={this.prependChatData}
                currentDialog={this.state.dialogData.items.length === 0
                  ? this.state.userDialog
                  : this.state.dialogData.items.find(item => item._id === this.dialogId)
                }
              />
            </LoaderElement>
          ) : (
            <div style={{margin: '0 auto'}}>
              <TitleElement
                title="Chat"
                titleStyle={{color: hightLightColor, margin: '36px 0px 24px', textAlign: 'center'}}
                description="Please login to chat with your English detective"
                descriptionStyle={{margin: '24px 0px 36px', textAlign: 'center'}}
              />
              <Auth initialize={this.initialize} />
            </div>
          )}
        </div>
      </div>
    );
  }
}


